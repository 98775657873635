<template>
<SmallModal class='clientShareModal' @close='$emit("close")' @doDefault='save'>
	<template v-slot:title>Share Client(s) with Team(s)</template>
	<template v-slot:message>
			<div class='field teamsField' :class='{ highlight: teams.length && !teamIds.length}'>
				<div class='label'>Team(s):</div>
				<div class='teams'>
					<div 
						v-for='(team, index) in teams'
						:key='index'
						@click='toggleTeam(team.id)'>
						<CheckButton :checked='teamIds.includes(team.id)' />
						<span class='label'>{{team.title}}</span>
					</div>	
				</div>
			</div>
			<div class='field clients'>
				<div class='label'>Client(s):</div>
				<div class='clientsList'>
					<div 
						v-for='(id, index) in $store.state.manageClients.selected'
						:key='index'
					>
						{{$store.state.clients[id].lastname}}, {{$store.state.clients[id].firstname}}
					</div>	
				</div>
			</div>
			<transition name='fade'><Loading v-show='working' /></transition>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='save'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t('elements.buttons.save')}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { clientsApi } from '@/services/api/modules/clinician/clients'	
import SmallModal from '@/components/common/modals/SmallModal'
import CheckButton from '@/components/common/buttons/Check'
import Loading from '@/components/common/Loading'

export default {
	components: {
		SmallModal,
		CheckButton,
		Loading
	},
	data() {
		return {
			teamIds: [],
			working: false
		}
	},
	computed: {
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		}
	},
	methods: {
		async save() {
			this.working = true 
			const payload = { 
				clientIds: [...this.$store.state.manageClients.selected],
				joinTeamIds: this.teamIds,
				unjoinTeamIds: this.teams.filter(t=>!this.teamIds.includes(t.id)).map(t=>t.id)
			}
			const [err, result] = await clientsApi.touch('share', payload)
			if (!err) {
				this.$store.dispatch('clients/parseOver', result)
				this.$emit('close')
			} else {
				this.working = false
			}
		},
		toggleTeam(teamId) {
			const i = this.teamIds.indexOf(teamId)
			if (i===-1) {
				this.teamIds.push(teamId)
			} else {
				this.teamIds.splice(i, 1)
			}
		}
	},
	mounted() {
		if (this.teams.length) {
			this.teamIds = this.teams.map(t=>t.id)
		}
	}
}

</script>

<style lang='scss'>
.clientShareModal {
/*
	.notSharedWarning {
		padding: $size-gutter * 2;
		padding-left: 100px + $size-gutter * 2;
		font-size: $size-font-small;
		line-height: $size-lineHeight-standard;
		color: $color-alert;	
	}
*/
	.teamsField.highlight * { color: $color-alert !important; }

	.teams {
		display: flex;
		align-items: center;
		justify-content: left;
		padding-left: $size-gutter * 2;
		column-gap: $size-gutter * 2;

		> div {
			display: flex;
			align-items: center;
			min-height: 24px;				
			font-size: $size-font-standard;
			cursor: pointer;				
			.checkButton { margin-right: $size-gutter; pointer-events: none; }
		}
	}

	.clients {
		padding: $size-gutter * 2 0;
		align-items: flex-start;
		.clientsList { font-size: $size-font-standard; line-height: $size-lineHeight-standard; padding-left: $size-gutter * 2; }
	}
}
</style>