<template> 
<transition name='slideDown'>
	<div class='contextMenuWrapper' @click.stop='close'> 	
		<div 
			:style='style'
			ref='menu'
			@mouseleave='onMouseLeave'
			@mouseenter='onMouseEnter'
		><slot /></div>
	</div> 
</transition>
</template> 
 
<script>
export default {
	name: 'ContextMenu',
	props: ['e','pos','persist'],
	components: {},
	data() { return {
		x: 0,
		y: 0,
		timer: null
	}},
	computed: {
		style() { return `top: ${this.y}px; left: ${this.x}px` }
	},
	methods: {
		onMouseEnter() { clearTimeout(this.timer) },
		onMouseLeave() { if (!this.persist) this.timer = setTimeout(this.close, 2000) },
		close() {
			clearTimeout(this.timer)
			this.$emit('close')
		}
	},
	mounted() {
		if (this.pos) {
			const el = this.pos.el.getBoundingClientRect()
			const menu = this.$refs.menu.getBoundingClientRect()			
			this.y = el.top + el.bottom
			let x
			if (this.pos.align==='center') {
				x = el.x + el.width / 2 - menu.width / 2
			} else if (this.pos.align==='right') {
				// placeholder
			} else { // default left
				x = el.x
			}
			if (x < 0 ) x = 0 
			else if (x + menu.width > this.$el.offsetWidth) x = this.$el.offsetWidth - menu.width + 1
			this.x = x
		} else {
			const wrapper = this.$el.getBoundingClientRect()
			const menu = this.$refs.menu.getBoundingClientRect()
			const x = this.e.clientX - wrapper.left - 20
			const y = this.e.clientY - wrapper.top - 20
			this.x = x - Math.max(x+menu.width-wrapper.width, 0)
			this.y = y - Math.max(y+menu.height-wrapper.height, 0)
		}
	}
}
</script>

<style lang='scss'>
.contextMenuWrapper {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 100000;
	> div { 
		padding: 0 $size-gutter * 2;
		animation: contextMenu 1200ms $ease-out-quint;
		position: absolute;
		background: $color-neutral-accent;
		border: 1px solid $color-neutral-shadow;
		box-shadow: 0 4px 6px -2px rgba(0,0,0,.08);
		transform-origin: 0 0;
		color: $color-black;
	}

	@keyframes contextMenu {
		from {
			opacity: 0;
			transform: translateY(-20px);
		}
		to {
			opacity: 1;
			transform: translateY(0);
		}
	}	

/*
	overflow-y: overlay;
	color: $color-neutral-shadow;
	&::-webkit-scrollbar {
		display: block;
		width: $size-gutter * 2;
		height: $size-gutter * 2;
		cursor: move;
	}

	&::-webkit-scrollbar-track {}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px;
		border: solid 3px transparent;
		border-radius: $size-gutter;
		color: inherit;
	}	

	&::-webkit-resizer,
	&::-webkit-scrollbar-button,
	&::-webkit-scrollbar-corner { display: none; }

	scrollbar-width: thin;	
*/	
}
</style>