<template>
<div class='teleHealth' @click='onClick'>
	<div class='button'>
		<div class='border'>
			<svg class='mediumIcon'>
				<use xlink:href='#video' />
			</svg>
		</div>
		<div class='label'>TeleHealth</div>
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import { generalApi } from '@/services/api/modules/clinician/general'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import ConfirmModal from '@/components/common/modals/Confirm'
import TeleHealthDemo from '@/components/clinician/banners/demo/TeleHealthDemo'
import ClientModal from '@/components/clinician/clients/modals/Client'

export default {
	name: 'TeleHealth',
	mixins: [smallModalMixin],
	components: { Loading },
	props: ['client'],
	data() { return { 
		loading: false
	}},
	computed: {
		htmlMessage() { 
			if (this.$store.state.profile.user.lang === 'en')
				return `
					<p>Welcome to SimpleSet TeleHealth (BETA)!  You are about to start a TeleHealth session with the following client:</p>
					<p><b>${this.client.firstname} ${this.client.lastname}, ${this.client.email}</b></p>
					<p>Instructions will be emailed to your client.</p>
					<p>The TeleHealth session will begin when your client joins.</p>
					<p>Here are a few tips to help you get the best experience:</p>
					<ul>
					<li>Double check you are not blocking pop-ups.</li>
					<li>Make sure you are on a fast internet connection.</li>
					<li>Find a private and quiet place with no distractions.</li>
					<li>Do not navigate away from the TeleHealth session until after it is completed.</li>
					</ul>
					<p>SimpleSet TeleHealth is secure and aligned with <b>PIPEDA / HIPAA / GDPR</b> requirements.</p>
					<ul>
					<li>Audio and video are end-to-end encrypted for your security and privacy.</li>
					<li>No audio or video will be recorded or stored by SimpleSet.</li>
					<li>Individual sessions will be limited to 75 minutes.</li>
					</ul>
					<p>By continuing with the TeleHealth session you agree to adhere to applicable legal requirements and clinical practice guidelines for your jurisdiction pertaining to the delivery of telerehabilitation and electronic communications.</p>
				`
			else if (this.$store.state.profile.user.lang === 'fr') 
				return `
					<p>Bienvenue sur SimpleSet Télésanté (BETA)!  Vous êtes sur le point de commencer une session Télésanté avec les clients suivants:</p>
					<p><b>${this.client.firstname} ${this.client.lastname}, ${this.client.email}</b></p>
					<p>Les instructions seront envoyées par courriel à votre client.</p>
					<p>La session de Télésanté débutera lorsque votre client aura joint.</p>
					<p>Voici quelques trucs afin de vous aider à vivre une expérience optimale:</p>
					<ul>
					<li>Vérifiez que vous ne bloquer pas les pop-ups.</li>
					<li>Assurez-vous d'utiliser une connexion internet rapide.</li>
					<li>Trouvez un lieu privé et calme, sans distractions.</li>
					<li>Ne quittez pas la session de télésanté avant qu'elle ne soit terminée.</li>
					</ul>
					<p>SimpleSet Télésanté est sécure et alignée avec les exigences de <b>PIPEDA / HIPAA / GDPR</b>.</p>
					<ul>
					<li>L'audio et la vidéo sont chiffrées de bout-en-bout pour assurer votre sécurité et votre confidentialité.</li>
					<li>Aucun audio ou vidéo ne sera enregistré ou stocké par SimpleSet.</li>
					<li>Les sessions individuelles seront limitées à 75 minutes.</li>
					</ul>
					<p>En poursuivant la session de télésanté, vous acceptez de vous conformer aux exigences légales applicables et aux directives de pratique clinique de votre juridiction concernant la prestation de téléréadaptation et de communications électroniques.</p>
				`
			else return null
		}
	},
	methods: {
		onClick() {
			if (this.$store.state.profile.user.clinicianType === 'demo') return this.showSmallModal(TeleHealthDemo, null, '960px', '540px')
			const confirm = () => {
				this.showSmallModal(ConfirmModal, {
					title: this.$t('views.startTeleHealth.title'),
					html: this.htmlMessage,
					ok: this.$t('elements.buttons.continue'),
					fn: this.initializeTeleHealth
				})
			}
			if (!this.client.email || this.client.emailErr) {
				this.showSmallModal(ClientModal, { client: this.client, emailRequired: true, onSave: confirm}) 
			} else {
				confirm()
			}
		},
		async initializeTeleHealth() {
			this.loading = true
			const [err, result] = await generalApi.touch('teleHealth', { clientId: this.client.key })
			this.loading = false
			if (!err) window.open(result.url, '_blank', 'location=0')
		}
	}
}
</script>

<style lang='scss'>
.teleHealth {
	border-left: 1px solid #e8e8e8;
	cursor: pointer;
	padding: 0 $size-gutter * 4;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative; 

	.button {
		.border { 
			margin: 0 auto;
			background: $color-neutral;
			width: 64px;
			height: 64px;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		.label {
			padding-top: $size-gutter;
			font-size: $size-font-standard;
			font-weight: bold;
			text-align: center; 
/*
			.miniButton {
				margin-left: $size-gutter;
			}
*/
		}
	}	

	&:hover {
		.button .border {
			background: $button-gradient-success;
			svg { color: $color-white; }
		}
	}
}
</style>
