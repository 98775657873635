<template>
<div 
	class='clientsPanel' 
	@mousedown='clickWithinStart'
	@click='e => clickWithinEnd(e, () => $store.dispatch("manageClients/clearSelected"))'
>
	<div class='topPanel'>
		<SearchInput 
			v-model='search'
			@submit='fetchTop'
			:placeholder='$t("views.clientsManager.phSearchClients")'
		/>
		<!--<ClientsPanelControls />-->
	</div>

	<div class='contentPanel'>
		<ScrollForever
			:itemHeight='40'
			:itemKeys='clientKeys'
			:itemComponent='itemComponent'
			:fetchNext='fetchNext'
			:loading='loading'
			:getSelected='getSelected'
			:setSelected='setSelected'
			:setSelecting='setSelecting'
			@showContextMenu='showContextMenu'
		>
			<template slot='header'>
				<div class='controls'>
					<div>
						<!--
							<div class='clientsFound'>Clients Found: {{total}}</div>
						-->
						<EnabledButton 
							@click='addClient'
							:disabled='$store.state.manageClients.archived' 
							:svg='"#plus"' :label='$t("elements.buttons.client")' 
							class='neutral tagPrimary addClientButton' 
						/>	
					</div>
					<div>

						<div v-if="teams.length" class='clientMax'>
							<span>Sharing Clients: </span>
							<span v-if="teams.length">
								&nbsp;&nbsp;{{teams.map(t=>t.title).join(", ")}}
							</span>
						</div>

						<!--
						<div v-if='teams.length' class='teams'>
							<span>Shared:</span>
							<div>
								<div 
									v-for='(team, index) in teams'
									:key='index'
									@click='toggleTeam(team.id)'>
									<CheckButton :checked='$store.state.manageClients.teamId===team.id' />
									<span class='label'>{{team.title}}</span>
								</div>	
							</div>
						</div>
					-->
						<div class='filter'>
							<div 
								v-if='!$store.state.manageClients.archived'
								@click='toggleFeedback'
							>
								<CheckButton :checked='$store.state.manageClients.feedback' />
								<span class='label'>{{$t('elements.labels.feedback')}}</span>
							</div>						
							<div @click='$store.dispatch("manageClients/setActive")'>
								<CheckButton :checked='!$store.state.manageClients.archived' />
								<span class='label'>Active</span>
							</div>
							<div @click='$store.dispatch("manageClients/setArchived")'>
								<CheckButton :checked='$store.state.manageClients.archived' />
								<span class='label'>Archived</span>
							</div>	
						</div>
					</div>



					<!--
					<EnabledButton 
						@click='addClient'
						:disabled='$store.state.manageClients.archived' 
						:svg='"#plus"' :label='$t("elements.buttons.client")' 
						class='neutral tagPrimary addClientButton' 
					/>				
					<div class='teams'>
						<span>Shared:</span>
						<div>
							<div 
								v-for='(team, index) in teams'
								:key='index'
								@click='$store.commit("manageClients/toggleTeamId", team.id)'>
								<CheckButton :checked='$store.state.manageClients.teamIds.includes(team.id)' />
								<span class='label'>{{team.title}}</span>
							</div>	
						</div>
					</div>
				-->
				</div>
				<!--
				<div class='resultInfoWrapper'>
					<div class='clientsFound'>Clients Found: {{total}}</div>
					<div class='resultsFilter'>
						<div 
							v-if='!$store.state.manageClients.archived'
							@click='toggleFeedback'
						>
							<CheckButton :checked='$store.state.manageClients.feedback' />
							<span class='label'>{{$t('elements.labels.feedback')}}</span>
						</div>						
						<div @click='$store.dispatch("manageClients/setActive")'>
							<CheckButton :checked='!$store.state.manageClients.archived' />
							<span class='label'>Active</span>
						</div>
						<div @click='$store.dispatch("manageClients/setArchived")'>
							<CheckButton :checked='$store.state.manageClients.archived' />
							<span class='label'>Archived</span>
						</div>												
					</div>					
				</div>
			-->
			</template>
			<template slot='sticky'>
				<ClientsHeader v-if='total' module='manageClients' :shared='$store.state.manageClients.shared' />
			</template>
		</ScrollForever>
		<transition name='fade'><Loading v-show='loading' /></transition>
	</div>

	<ContextMenu 
		v-if='contextMenu' 
		:e='contextMenu'
		@close='contextMenu=false'
	>
		<div 
			v-if='canArchiveSelected'
			class='iconTextButton neutral'
			@click='$store.dispatch("manageClients/archive", { archived: true })'
		>
			<svg class='smallIcon'>
				<use xlink:href='#archive' />
			</svg>
			<span class='label'>Archive ({{$store.state.manageClients.selected.length}})</span>
		</div>	
		<div 
			v-else-if='canUnarchiveSelected'
			class='iconTextButton neutral'
			@click='$store.dispatch("manageClients/archive", { archived: false })'
		>
			<svg class='smallIcon'>
				<use xlink:href='#unarchive' />
			</svg>
			<span class='label'>Unarchive ({{$store.state.manageClients.selected.length}})</span>
		</div>	
		<div 
			v-if='teams && teams.length'
			class='iconTextButton neutral'
			@click='showShare'
		>
			<svg class='smallIcon'>
				<use xlink:href='#team' />
			</svg>
			<span class='label'>Share ({{$store.state.manageClients.selected.length}})</span>
		</div>						
		<div 
			class='iconTextButton neutral'
			:class='{ disabled: !canDestroySelected }'
			@click='confirmDelete'
		>
			<svg class='smallIcon'>
				<use xlink:href='#delete' />
			</svg>
			<span class='label'>Delete <template v-if='canDestroySelected'>({{$store.state.manageClients.selected.length}})</template></span>
		</div>				
	</ContextMenu>	
</div>
</template>

<script>
import Cookies from 'js-cookie'
import { UI } from '@/utilities'
import { clickWithin } from '@/components/common/mixins/clickWithin'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Loading from '@/components/common/Loading'
import SearchInput from '@/components/common/SearchInput'
import CheckButton from '@/components/common/buttons/Check'
import ScrollForever from '@/components/common/ScrollForever'
import EnabledButton from '@/components/common/buttons/Enabled'
import ContextMenu from '@/components/common/ContextMenu'
import ConfirmDeleteModal from '@/components/common/modals/ConfirmDelete'
import ClientsHeader from './ClientsHeader'
import ClientRow from './ClientRow'
import ClientModal from './modals/Client'
import ClientShareModal from './modals/ClientShare'

export default {
	name: 'clientsPanel',
	mixins: [clickWithin, smallModalMixin],
	components: {
		EnabledButton,
		SearchInput,
		Loading,
		ScrollForever,
		ClientsHeader,
		CheckButton,
		ContextMenu
	},
	data: () => ({
		itemComponent: ClientRow,
		contextMenu: false,
		ui: null
	}),
	computed: {
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		},
		loading() { return this.$store.state.manageClients.apiPending },
		clientKeys() { return this.$store.state.manageClients.list },
		total() { return this.$store.state.manageClients.meta.total },
		search: {
			get() {
				return this.$store.state.manageClients.search
			},
			set(value) {
				this.$store.dispatch('manageClients/setSearch', value)
			}
		},
		canArchiveSelected() {
			return (
				this.$store.state.manageClients.selected.length && 
				this.$store.state.manageClients.archived === false
			)
		},
		canUnarchiveSelected() {
			return (
				this.$store.state.manageClients.selected.length && 
				this.$store.state.manageClients.archived === true
			)
		},
		canDestroySelected() {
			return (
				this.$store.state.manageClients.selected.length && 
				this.$store.state.manageClients.selected.every(id => !this.$store.state.clients[id].setCount) &&
				this.$store.state.manageClients.selected.every(id => !this.$store.state.clients[id].outcomeCount)
			)
		}		
	},
	methods: {
		fetchTop() { this.$store.dispatch('manageClients/fetchTop') },
		fetchNext() { this.$store.dispatch('manageClients/fetchNext') },
		getSelected() { return this.$store.state.manageClients.selected },
		setSelected(keys) { this.$store.dispatch('manageClients/setSelected', keys) },
		setSelecting(keys) { this.$store.dispatch('manageClients/setSelecting', keys) },
		toggleFeedback() {
			const value = !this.$store.state.manageClients.feedback
			Cookies.set(`${this.$store.state.profile.user.id}-clientFeedback`, value)
			this.$store.dispatch('manageClients/setFeedback', value).then(this.fetchTop)
		},
		toggleShared() {
			const value = !this.$store.state.manageClients.shared
			Cookies.set(`${this.$store.state.profile.user.id}-teamClients`, value)
			if (!value) this.$store.commit('manageClients/setSortColumn', null)			
			this.$store.dispatch('manageClients/setShared', value).then(this.fetchTop)
		},
		toggleTeam(teamId) {
			this.$store.commit("manageClients/toggleTeamId", teamId)
			this.ui.set('manageClientsTeamId', this.$store.state.manageClients.teamId)
			this.fetchTop()
		},
		addClient() {
			if (!this.$store.state.manageClients.archived) this.showSmallModal(ClientModal, { listModule: 'manageClients' })
		},
		confirmDelete() {
			const items = this.$store.state.manageClients.selected.map(key => this.$store.state.clients[key].lastname +', '+ this.$store.state.clients[key].firstname)
			const fn = () => this.$store.dispatch('manageClients/destroy')
			this.showSmallModal(ConfirmDeleteModal, { items, fn })
		},	
		showShare() {
			this.showSmallModal(ClientShareModal)
		},
		showContextMenu(e) {
			this.contextMenu = e 
		}
	},
	watch: {
		'$route.params.clientId': function(v) { // in case using forward or back buttons, this keeps it working -- do this better in the next version!
			this.$store.dispatch('manageClients/setSelected', +v)
		}
	},
	mounted() {
		this.ui = new UI(this.$store.state.profile.user.id)
		const teamId = this.ui.get('manageClientsTeamId')
		if (this.teams.find(t=>t.id===teamId)) this.$store.commit("manageClients/setTeamId", teamId) 
	}
//	mounted() { this.fetchTop() }
}
</script>

<style lang='scss'>
.clientsManager .clientsPanel {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;
	.headerPanel {}

	.topPanel {
		margin: $size-gutter * 2;
		margin-bottom: 0;
		

		.searchInput {
			border: 1px solid $color-neutral-shadow;
			height: $size-control-height + 2;
		}

	}

	.contentPanel {
		position: relative;

		.controls {
			display: flex;
			align-items: flex-start;
			padding: $size-gutter * 2;
			/*
			padding-top: $size-gutter * 3;
			padding-right: $size-gutter * 2;
			padding-left: $size-gutter * 2;
			*/
			min-height: 100px;
			> div:first-child { flex: 0; }
			> div:last-child { flex: 1; }

			.addClientButton {
				height: 24px;
				margin-top: $size-gutter * 2;
			}

			.clientsFound {
				font-size: $size-font-standard;
				padding-left: $size-gutter * 2 + 1;	
				white-space: nowrap;		
				display: flex;
				align-items: center;
				height: 24px;
			}

			.clientMax {
				height: 24px;
				display: flex;
				align-items: center;
				justify-content: right;
				font-size: $size-font-standard;
				padding-right: $size-gutter * 2;
				
				span:first-child { color: $color-success; }
			}

			.teams {
				display: flex;
				align-items: center;
				justify-content: right;
				> span { font-size: $size-font-standard; font-weight: bold; }
				> div {
					display: flex; 
					column-gap: $size-gutter * 2;
					padding: 0 $size-gutter * 2;
					flex-wrap: wrap;
					justify-content: right;
				}
				> div > div {
					display: flex;
					align-items: center;
					min-height: 24px;				
					font-size: $size-font-standard;
					cursor: pointer;				
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
				}
			}	
/*
			.teams + .filter { margin-top: 2px; }
*/
			.filter {
				display: flex;
				align-items: center;
				justify-content: right;
				display: flex; 
				gap: $size-gutter * 2;
				padding: 0 $size-gutter * 2;
				&:only-child { margin-top: 14px;}
				> div {
					display: flex;
					align-items: center;
					font-size: $size-font-standard;
					cursor: pointer;
					min-height: 24px;	
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
				}
			}				
		
		}



	/*	
		.resultInfo { padding-top: 0; border: 1px solid blue; > div { padding: 0;} .total { margin: 0 !important; }}

		.resultInfoWrapper {
			height: $size-control-height;
			display: flex;
			align-items: center;
						margin-bottom: $size-gutter * 2; 

		}

		.clientsFound {
			font-size: $size-font-standard;
			padding-left: $size-gutter * 4;	
			white-space: nowrap;		
		}

		.resultsFilter {
			margin-left: auto;
			display: flex;
			padding-right: $size-gutter * 4;
			position: relative;


			> div {
				display: flex;
				align-items: center;
				font-size: $size-font-small;
				font-weight: bold;
				padding-left: $size-gutter * 2;
				cursor: pointer;
				.checkButton { margin-right: $size-gutter; pointer-events: none; }
			}
		}		

		.controls {
			display: flex;
			align-items: center;
			padding-right: $size-gutter * 2;
			padding-left: $size-gutter * 2;
			.addClientButton { margin-right: auto; }


			.teams {
				display: flex;
				align-items: center;
				> span { font-size: $size-font-standard; font-weight: bold; margin-right: $size-gutter * 2;}
				> div {
					display: flex; 
					gap: $size-gutter * 2;
					padding: $size-gutter $size-gutter * 2;
					min-height: $size-control-height + 2;
				}
				> div > div {
					display: flex;
					align-items: center;
					font-size: $size-font-small;
					font-weight: bold;
					cursor: pointer;
					.checkButton { margin-right: $size-gutter; pointer-events: none; }
				}
			}	
		}

*/

	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}
}
</style>
