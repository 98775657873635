<template>
<div class='clientPanel'>
	<Scroll v-if='$store.getters["manageClients/detail"]' :fetchNext='fetchNext'>
		<div v-if="client.emailErr" class="emailErr" @click="editClient">
			ALERT:  Emails to {{client.firstname || "this Client"}} are bouncing.
			<!--<br />
			Please investigate and update the email address on file:<br />
			<b><i>{{client.email}}</i></b>-->
		</div>
		<div class='clientInfoWrapper'>
			<div class='clientHeader'>
				<ClientInfo :client='client' />
				<TeleHealth v-if='!client.archived' :client='client' />
			</div>
		</div>
		<ClientPanelTabs :client='client' />
		<div v-if='view === "sets"' class='setsPanel'>
			<ClientSets ref='clientSets' :client='client' @working='v=>working=v' />
		</div>
		<div v-else-if='view === "outcomes"' class='outcomesPanel'>
			<ClientOutcomes ref='clientOutcomes' :client='client' @working='v=>working=v' />
		</div>
		<div v-else-if='view === "logs"' class='logPanel'>
			<ClientLogs ref='clientLogs' :client='client' @working='v=>working=v' />
		</div>
	</Scroll>
	<transition name='fade'><Loading v-show='working' /></transition>
</div>
</template>

<script>
import { smallModalMixin } from '@/components/common/mixins/modal'
import Scroll from '@/components/common/Scroll'
import Loading from '@/components/common/Loading'
import ClientPanelTabs from './ClientPanelTabs'
import ClientModal from './modals/Client'
import ClientSets from './ClientSets'
import ClientInfo from './ClientInfo'
import TeleHealth from './TeleHealth'

export default {
	name: 'clientPanel',
	mixins: [smallModalMixin],
	components: {
		ClientPanelTabs,
		ClientSets,
		ClientInfo,
		TeleHealth,
		Scroll,
		Loading,
		ClientOutcomes: () => import(/* webpackChunkName: "group-clinician-outcomes" */  './ClientOutcomes'),
		ClientLogs: () => import(/* webpackChunkName: "group-clinician-clientlogs" */  './ClientLogs'),
	},
	data() { return { working: false }},
	computed: {
		client() { return this.$store.getters['manageClients/detail'] },
		view() { return this.$store.state.manageClients.clientView }
	},
	methods: {
		fetchNext() {
			if (this.view === "sets") {
				if (this.$refs.clientSets) this.$refs.clientSets.fetchNext()
			} else if (this.view === "outcomes") {
				if (this.$refs.clientOutcomes) this.$refs.clientOutcomes.fetchNext()
			} else if (this.view === "logs") {
				if (this.$refs.clientLogs) this.$refs.clientLogs.fetchNext()
			}
		},
		editClient() { 
			this.showSmallModal(ClientModal, { client: this.client }) 
		}
	}
//	mounted() {
		// if (this.client.emr) this.$store.dispatch('flash/showAction', 'copyToClipboard')
//	}
}
</script>

<style lang='scss'>
.clientsManager .clientPanel {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr;
	background: $color-neutral-panel;

	.clientInfoWrapper {
		border: $size-gutter solid $color-neutral-panel;
		border-bottom: $size-gutter + 1 solid $color-neutral-panel;
		border-top: none;
	}

	.emailErr { 
		margin: $size-gutter * 2; 
		padding: 0 $size-gutter * 3;
		height: 42px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba($color-alert-accent, .1);
		border: 1px solid rgba($color-alert-accent, .6);
		font-size: $size-font-large;
		color: $color-alert;
		line-height: $size-lineHeight-large;
		text-align: center;
		cursor: pointer;
	}

	.resultInfo {
		margin-top: $size-gutter * 2 + 1;
	}

	.clientHeader {
		padding: $size-gutter * 2 + 2 $size-gutter;
		padding-bottom: $size-gutter * 2 - 2;
		display: flex;
		> div:first-child { flex: 1;}
	}

	.setsPanel, .outcomesPanel, .logsPanel {
		position: relative;
	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}
}
</style>
