<template>
<div class='languageInput'>
	<div class='lang' @click='$emit("input", "en")'>
		<CheckButton :checked='value === "en"' />
		{{$t("elements.langs.en")}}
	</div>
	<div class='lang' @click='$emit("input", "fr")'>
		<CheckButton :checked='value === "fr"' />
		{{$t("elements.langs.fr")}}
	</div>
</div>
</template>

<script>
import CheckButton from '@/components/common/buttons/Check'

export default {
	name: 'LanguageInput',
	components: { CheckButton },
	props: ['value']
}
</script>

<style lang='scss'>
.languageInput {
	display: flex;
	align-items: center;
	min-height: $size-control-height;

	> div {
		font-size: $size-font-standard;
		align-items: center;
		display: flex;
		padding-left: $size-gutter * 2;
		cursor: pointer;

		.checkButton {
			pointer-events: none;
		}

		svg {
			margin-right: $size-gutter;
		}
	}
}
</style>

