<template>
<div class='setHistoryPoint'>

	<div class='content'>
		<Scroll>
			<div class='header'>
				{{$t('views.setHistory.savedOn')}} {{created}}
			</div>

			<div v-if='point.title || point.instructions' class='coversheet'>
				<div v-if='point.title' class='title'>{{point.title}}</div>
				<div v-if='point.instructions' class='instructions' v-html='point.instructions' />
			</div>

			<div class='elements'>
				<template v-for='(element, index) in point.elements'>

					<div v-if='element.type === "setExercise" || element.type === "setExerciseRef"' class='exercise element' :key='index'>
						<div class='title'>{{element.title}}</div>
						<div v-if='element.parameters.length' class='parameters'>
							<span
								v-for='(parameter, index) in element.parameters'
								:key='index'
								class='parameter'
							><b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' /></span>
						</div>
						<div v-if='element.resources.length' class='resources'>
							<div
								v-for='(resource, index) in element.resources'
								:key='index'
								class='resource'
							>
								<div class='resourceImgContainer'><div><LazyImage v-if='resource.url' :src='resource.url' /></div></div>
								<div v-if='resource.caption' class='caption' v-html='resource.caption' />
							</div>
						</div>
						<div v-if='element.instructions' class='instructions' v-html='element.instructions' />
					</div>

					<div v-else-if='element.type === "setDivider"' class='divider element' :key='index'>
						<div class='title'>{{element.title}}</div>
						<div v-if='element.instructions' class='instructions' v-html='element.instructions' />
					</div>

				</template>
			</div>

		</Scroll>
	</div>

</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import Scroll from '@/components/common/Scroll'
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'SetPoint',
	mixins: [dateTime],
	components: { Scroll, LazyImage },
	props: [],
	data: () => ({}),
	computed: {
		point() { return this.$store.getters['setHistory/point'] },
		created() { return this.dateTime(this.point.created) },
	},
	methods: {}
}
</script>

<style lang='scss'>
.setHistoryPoint {
	@include fill;
	display: grid;
	grid-template-rows: 1fr;
	background: $color-white;

	.header {
		height: $size-control-height;
		text-align: right;
		font-size: $size-font-standard;
		padding: $size-gutter * 4;
	}

	.content {
		position: relative;
	}

	.coversheet {
		background: $color-white;
		padding: $size-gutter * 4;
		margin: $size-gutter * 2;
		margin-bottom: 0;

		.title {
			font-size: $size-font-large;
			font-weight: bold;
			text-align: center;
			text-decoration: underline;
		}

		> div:nth-child(2) {
			margin-top: $size-gutter * 2;
		}
	}

	.elements {
		margin: $size-gutter * 2;
	}

	.element {
		margin-bottom: 1px;
		padding: $size-gutter * 2;

		&.exercise {
			background: $color-white;
		}

		&.divider {
			background: $color-neutral-silver;
			border-top: $size-gutter solid $color-primary-accent;
			margin-top: $size-gutter * 2;

			&:first-child { margin-top: 0; }
		}

		.title {
			font-size: $size-font-large;
			font-weight: bold;
			text-decoration: underline;
			color: $color-primary;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.parameters {
			font-size: $size-font-standard;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: $size-gutter;

			.parameter {
				margin-right: $size-gutter;
				padding-left: $size-gutter;
				border-left: 1px solid $color-neutral;

				&:first-child {
					padding-left: 0;
					border-left: none;
				}
			}
		}

		.resources {
			padding: 1px;
			display: grid;
			grid-gap: 1px;
			grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
			margin-top: $size-gutter * 2;

			.resource {
				background: $color-white;
				box-shadow: 0 0 0 1px $color-neutral-shadow;
			}

			.caption {
				text-align: center;
				background: white;
				padding: $size-gutter;
			}

			.resourceImgContainer {
				width: 100%;
				padding-top: 75%;
				position: relative;

				> div {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.instructions {
			margin-top: $size-gutter * 2;
		}
	}

	.instructions {
		@include rich;
	}
}
</style>
