<template>
<div 
	v-tap='onClick'
	class='iconTextButton' 
	:class='{ disabled }'
>
	<svg class='smallIcon'>
		<use :xlink:href='svg' />
	</svg>
	<span class='label'>{{label}}</span>
</div>
</template>

<script>
export default {
	name: 'EnabledButton',
	props: ['disabled', 'svg', 'label'],
	methods: {
		onClick() {
			if (!this.disabled) this.$emit('click')
		}
	}
}
</script>