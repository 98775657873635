<template> 
<div class='scrollContainer' @scroll='onScroll' :class='{ freeze }'> 
	<div class='scrollContent' ref='scrollContent'> 
		<slot /> 
		<AtBottom v-if='!!fetchNext' :action='atBottom' />
	</div> 
</div> 
</template> 
 
<script>
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';
//import { throttle } from 'lodash'
import AtBottom from '@/components/common/AtBottom'

export default {
	name: 'Scroll',
	props: ['fetchNext', 'loading', 'freeze'],
	components: {
		AtBottom
	},
	data() { return {
		lastScrollTop: 0,
		ignoreEvents: false,
		scrollEl: null
	}},
	methods: {
		onScroll(e) {
			if (this.ignoreEvents) return false
			const st = e.target.scrollTop
			const diff = this.lastScrollTop - st
			this.lastScrollTop = st
			if (st>=0) this.$emit('diff', { diff, el: this.$el })
		},
		/*
		onScroll: throttle(function() {
			if (this.ignoreEvents) return false
			const st = this.$refs.scrollContent.scrollTop
			const acc = Math.abs(st - this.lastScrollTop)
			if (st > this.lastScrollTop && st > this.topBound && acc > 25) this.scrollDir = 'down'
			else if (st < this.lastScrollTop && acc > 25) this.scrollDir = 'up'
			if (st < this.topBound) this.nearTop = true
			else this.nearTop = false
			this.lastScrollTop = this.$refs.scrollContent.scrollTop
		}, 100, { leading: false, trailing: true }),
		*/
		atBottom: function() {
			if (!this.loading) this.fetchNext()
		},
		scrollToTop: function() {
			this.ignoreEvents = true
			setTimeout(() => this.ignoreEvents = false, 200)						
			this.$el.scrollTop = 0
		},
		scrollToBottom: function(selector) {
			this.ignoreEvents = true
			setTimeout(() => this.ignoreEvents = false, 200)			
			if (selector) {
				const els = this.$el.querySelectorAll(selector)
				els[els.length - 1].scrollIntoView(false)
			} else {
				this.$el.scrollTop = this.$el.scrollHeight
			}
		},
		setScrollOffset: function(offset) {
			this.ignoreEvents = true
			setTimeout(() => this.ignoreEvents = false, 200)			
			this.$el.scrollTop += offset
		}
	},
	mounted() {
		const simpleBar = new SimpleBar(this.$el, { clickOnTrack: true})
		this.scrollEl = simpleBar.getScrollElement()
		this.scrollEl.addEventListener('scroll', this.onScroll)
	}
}
</script>
<style lang='scss'>
.scrollContainer {
	position: absolute !important;
	width: 100%;
	height: 100%;
	overflow-y: overlay;	
	overflow-x: hidden;
	padding: 0 !important;

	.scrollContent {
		min-height: calc(100% - 14px);
		margin: 0 !important;
	}
	.freeze { overflow: hidden !important; }
}

.desktop .scrollContainer {
/*
	color: transparent;
	/* transition: color linear 200ms; */

	.scrollContent { color: $color-black; }
/*
	&::-webkit-scrollbar:horizontal {
		display: block;
		height: $size-gutter * 3;
		cursor: move;
		background: $color-neutral-shadow;

	}

	&::-webkit-scrollbar-track {}

	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px;
		color: #fff;
		background: $color-neutral-shadow;
		border: 1px solid transparent;
		border-left: none;
		border-right: none;
	}	

	&::-webkit-resizer,
	&::-webkit-scrollbar-button,
	&::-webkit-scrollbar-corner { display: none; }

	scrollbar-width: thin;	
*/
}

_::-webkit-full-page-media, _:future, :root .desktop .scrollContainer::-webkit-scrollbar { /* safari only hack since overlay doesn't work */
	display: none !important;		
}

.scrollContainer {
	&::-webkit-scrollbar:vertical {
		display: none !important;
	}

}

.simplebar-vertical .simplebar-scrollbar:before {
  background: darken($color-neutral, 12%);
  border-radius: 3px;
  left: 5px;
  right: 5px;
  /*
  transition: left 200ms, right 200ms, border-radius 200ms, opacity 0.2s 0.5s linear;
  */
}
/*
.simplebar-track {
	pointer-events: auto;
	&:hover .simplebar-scrollbar:before {
	  	left: 1px;
	  	right: 1px;
	  	border-radius: 6px;
	}
}
.simplebar-dragging .simplebar-scrollbar:before {
  	left: 1px;
  	right: 1px;
  	border-radius: 6px;
}

*/

.simplebar-track.simplebar-vertical {
  width: 14px;
}

.simplebar-vertical .simplebar-scrollbar:before {
  background: darken($color-neutral, 20%);
  border-radius: 3px;
  left: 5px;
  right: 5px;
  /*
  transition: left 200ms, right 200ms, border-radius 200ms, opacity 0.2s 0.5s linear;
  */
}

.simplebar-track.simplebar-horizontal {
  height: 21px;
}

.simplebar-horizontal {
/*
	z-index: 100;
	background: $color-neutral-dark;
*/
	.simplebar-scrollbar:before {
/*
	  opacity: 1;
	  background: #fff;
	  border-radius: 0;
	  top: 5px;
	  left: 5px;
	  right: 5px;
	  bottom: 5px;
*/	  
	opacity: 0.5;
	  border-radius: 10px;
	}
}

</style>