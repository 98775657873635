<template>
<div class='setHistoryFooter modalControls'>
	<CancelButton @click='$emit("close")' />
	<div class='loadButton iconTextButton primary' @click='load'>
		<svg class='smallIcon'>
			<use xlink:href='#designer' />
		</svg>
		<span class='label'>{{$t("elements.buttons.load")}} {{created}}</span>
	</div>
</div>
</template>

<script>
import { defaultModalAction } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import CancelButton from '@/components/common/buttons/Cancel'

export default {
	name: 'SetHistoryFooter',
	mixins: [dateTime, defaultModalAction],
	components: { CancelButton },
	props: [],
	data: () => ({}),
	computed: {
		point() { return this.$store.getters['setHistory/point'] },
		created() { return this.dateTime(this.point.created) }
	},
	methods: {
		doDefault() { this.load() },
		load() {
			this.$store.dispatch('setBuilder/fetchHistory', this.point.id)
			this.$router.replace({ name: 'designer' }).catch(() => {})
			this.$emit('close')
			// test
		}
	}
}
</script>

<style lang='scss'>
.setHistoryFooter {
}
</style>
