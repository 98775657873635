<template>
<div class='teleHealthDemo'>
	<transition name='fade'>
		<Loading v-if='!loaded' />
	</transition>
	<video autoplay playsinline muted loop src='/mp4/telehealth.mp4' @canplay='loaded = true' :class='{ loaded }' crossOrigin='anonymous' />
</div>
</template>

<script>
import Loading from '@/components/common/Loading'

export default {
	name: 'TeleHealthDemo',
	components: { Loading },
	data: () => ({ loaded: false })
}
</script>

<style lang='scss'>
.teleHealthDemo {
	background: $color-neutral-silver; 

	video { 
		width: 100%; 
		background: $color-white; 
		opacity: 0;
		transition: opacity 200ms linear;

		&.loaded { opacity: 1; }
	}
}
</style>
