<template>
<div class='clientSetRow' :class='{ selected, disabled: !set.enabled, previewVisible: previewVisible && selected }' @click.stop='onClick'>
	<div class='titleCol'>
		<svg v-if='locked' class='lockIcon smallIcon'>
			<use xlink:href='#lock' />
		</svg>
		<!--
		<div 
			v-else
			class='renameButton miniButton neutral' 
			@mousedown.stop @click.stop='rename'
		>{{ $t('elements.buttons.rename') }}</div>
		-->		
		<span class='title'>
			{{$store.getters['manageClientSets/title'](set.key)}}
			<template v-if="!emailed"><br /><span>Unsent changes</span></template>
		</span>
		<div 
			v-if='inDesigner'
			class='inDesigner miniButton primary' 
			@mousedown.stop @click.stop='onDblClick'
		>{{ $t('elements.labels.inDesigner') }}</div>
		<div 
			v-else-if='restoreDraft'
			class='restoreDraftButton miniButton success' 
			@mousedown.stop @click.stop='loadDraft'
		>{{ $t('elements.buttons.restoreDraft') }}</div>
		<div 
			v-if='!inDesigner'
			class='openButton miniButton neutral' 
			@mousedown.stop @click.stop='onDblClick'
		>{{ $t('elements.buttons.load') }}</div>
	</div>
	<div class='updatedCol'><div>
		<div class='updated'>{{updated}}</div>
		<div v-if='set.savedBy' class='savedBy'><div>{{set.savedBy}}</div></div>
	</div></div>
	<div class='sentCol'><div>
		<!--
		<svg v-if='emailed' class='emailedIcon miniIcon'>
			<use xlink:href='#email' />
		</svg>
	-->
		<div class='lastSent'>{{lastSent}}</div>
		<div class='status' :class='[set.status]'>
			<template v-if="set.status==='delivery'">Delivered</template>
			<template v-if="set.status==='open'">Opened</template>
			<template v-if="set.status==='click'">Clicked</template>
			<template v-else-if="set.status==='bounce'">Bounced</template>
			<template v-else-if="set.status==='complaint'">Marked as Spam</template>
		</div>
	</div></div>	
	<div class='enabledCol' @click.stop @dblclick.stop>
		<DraggableSwitch
			:value='set.enabled'
			:clickable='false'
			@input='setEnabled'
			@click.native.stop
		/>
		<div class='views' :class='{ enabled: set.enabled }'>{{$t('elements.labels.views')}}: {{set.viewCount}}</div>
		<!--
			<div 
				v-if='set.reports'
				class='reportsButton miniButton neutral' 
				@mousedown.stop @click.stop='adherence'
			>{{ $t('elements.buttons.tracking') }}</div>
		-->
	</div>
	<div v-if='selected' class='detail' @click.stop :class='{ loaded: previewVisible }'><div>
		<div>
			<SetsPreview :setKey='set.key' @previewVisible='onPreviewVisible' />
		</div>
		<div v-if='previewVisible'>
			<div v-if='!inDesigner' class='iconTextButton silver loadInDesigner' @click='onDblClick'>
				<svg class='smallIcon'>
					<use xlink:href='#designer' />
				</svg>
				<span class='label'>{{$t("elements.buttons.loadInDesigner")}}</span>
			</div>			
			<HistoryButton @click='history' class='historyButton' />
		</div>
	</div></div>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { smallModalMixin, largeModalMixin } from '@/components/common/mixins/modal'
import TextInputModal from '@/components/common/modals/TextInput'
import DraggableSwitch from '@/components/common/DraggableSwitch'
import Tracking from '@/components/clinician/adherence/Tracking'
import SetsPreview from '@/components/clinician/preview/ClientSets'
import HistoryButton from '@/components/common/buttons/History'
import SetHistory from '@/components/clinician/designer/setBuilder/modals/setHistory/SetHistory'


export default {
	name: 'ManageClientSetRow',
	mixins: [dateTime, smallModalMixin, largeModalMixin],
	components: { DraggableSwitch, SetsPreview, HistoryButton },
	props: ['itemKey'],
	data() { return { previewVisible: false }},
	computed: {
		set() { return this.$store.state.manageClientSets.sets[this.itemKey] || {} },
		updated() { 
			this.langBackdoor
			return this.date(this.set.updated) 
		},
		lastSent() {
			this.langBackdoor
			return this.set.lastSent ? this.date(this.set.lastSent) : '--'
		},
		emailed() {
			return this.set.lastSent && this.set.lastSent > this.set.updated
		},
		inDesigner() { return this.$store.getters['sets/getKeyById'](this.set.key) },
		locked() { return this.$store.state.manageClientSets.client ? this.$store.state.clients[this.$store.state.manageClientSets.client].archived : null },
		restoreDraft() { return this.set.draftId && !this.locked },
		selected() {
			return (
				this.$store.state.manageClientSets.selected === this.set.key
			)
		}
	},
	methods: {
		onClick() {
			//if (e.ctrlKey || e.metaKey || e.shiftKey) return 
			//this.$store.dispatch('manageClientSets/clearSelected')
			this.toggleSelected()
		},
		onDblClick() {
			this.load(this.set.id)
		},
		onPreviewVisible() {
			this.previewVisible = true
			this.$el.scrollIntoView({ block: 'nearest' })
		},
		toggleSelected() {
			//if (e && e.shiftKey) this.selectUpTo()
			//else this.$store.dispatch('manageClientSets/toggleSelected', this.set.key)
			this.$store.dispatch('manageClientSets/toggleSelected', this.set.key)
		},
		selectUpTo() {
			this.$store.dispatch('manageClientSets/selectUpTo', { key: this.set.key, list: this.$store.state.manageClientSets.list })
		},
		rename() {
			const fn = savename => this.$store.dispatch('manageClientSets/rename', { key: this.set.key, savename })
			this.showSmallModal(TextInputModal, {
				title: this.$t('views.editSetTitle.title'),
				placeholder: this.$t('views.editSetTitle.phSetTitle'),
				value: this.set.savename || this.set.title,
				button: this.$t('elements.buttons.saveAs'),
				maxlength: 100,
				fn
			})			
		},
		adherence() {
			this.showLargeModal(Tracking, { setId: this.itemKey, client: this.$store.getters['manageClients/detail'], showLoad: true }, '100%') 
		},
		setEnabled(value) {
			this.$store.dispatch('manageClientSets/setEnabled', { key: this.set.key, value })
		},
		loadDraft() {
			this.load(this.$store.getters['sets/getIds'].includes(this.set.id) ? this.set.id : this.set.draftId)
		},
		load(id) {
			this.$store.dispatch('setBuilder/fetch', id)
				.then(() => this.$router.replace({ name: 'designer' }))
		},
		history() {
			this.showLargeModal(SetHistory, { setKey: this.set.key }) 
		}
	},
	mounted() {
		if (this.selected) this.$store.dispatch('clientSetsPreview/setList', [this.itemKey])
	}
}
</script>

<style lang='scss'>
@import './scss/clientSetRow.scss';

.clientsManager .clientSetRow {

	&.previewVisible + .clientSetRow {
		border-top: 1px solid $color-neutral-shadow;
	}

	&:hover { background: $color-neutral-silver;  }

	overflow: hidden;

	> div:not(.detail) { z-index: 10; }


	&.selected > div:not(.detail) { background: $color-focus; }

	.checkButton {
		margin-right: $size-gutter * 2;
	}

	.inDesigner {
		margin-left: auto;
		white-space: nowrap;
	}

	.lockIcon {
		margin-right: $size-gutter * 2;
		color: $color-neutral-shadow;
	}

	.renameButton, .openButton {
		border: 1px solid $color-neutral-shadow;
	}

	.openButton {
		margin-left: auto;
	}

	.restoreDraftButton {
		margin-left: auto;
		margin-right: $size-gutter * 2;
		cursor: pointer;
		white-space: nowrap;
		& ~ .openButton { 
			margin-left: 0;
		}
	}

	.enabledCol {
		flex: 2;
		display: flex;
		justify-content: center;
		border-right: 1px solid $color-neutral-shadow;

		.draggableSwitch { z-index: 10; &:only-child { margin-right: auto; }}
		.trackFalse { background: $color-neutral-shadow; }
		.trackTrue { background: $color-success; }
		.handle { background: $color-neutral-accent; }
		.views {
			font-size: $size-font-small;
			margin-left: $size-gutter;
			margin-right: auto;
			color: $color-neutral-shadow;
			&.enabled { color: $color-black; }
		}
		.reportsButton {
			margin-left: $size-gutter * 2;
			margin-left: auto;
			border: 1px solid $color-neutral-shadow;
		}
	}
/*
	.sentCol {
		flex: 0;
		flex-basis: 42px;
		.emailedIcon {
			color: $color-primary-accent;
			left: -1px;
			position: relative;
		}		
	}
*/

	.sentCol {



	}

	&.disabled {}

	.detail.loaded {
		z-index: 0;
		position: relative;
		flex-basis: 100%;
		border-top: 1px solid $color-neutral-shadow;
		border-left: none;
		display: grid;
		animation: slideDown 600ms $ease-out-quint;

		> div {
			position: relative;
			width: 100%;
			background: $color-neutral-panel;
			display: grid;
			grid-template-columns: 3fr 2fr;
			grid-gap: $size-gutter * 2;
			padding: $size-gutter * 2;

			> div {
				overflow: hidden;
			}
		}

		.loadInDesigner {
			height: $size-control-height * 2 + 2;
			border: 1px solid $color-neutral-shadow;
			margin-bottom: $size-gutter * 2;
		}

		.historyButton {
			border: 1px solid $color-neutral-shadow;
			height: $size-control-height + 2px;
		}

		&:after {
			position: absolute;
			bottom: -500px;
			left: 0;
			width: 100%;
			height: 500px;
			background: $color-neutral-panel;
			content: '';
		}
	}

}

.clientsManager .hover .clientSetRow { background: $color-neutral-silver; }

</style>