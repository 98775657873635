<script>
import 'chartjs-plugin-datalabels'
import { Line } from 'vue-chartjs'
import { dateTime } from '@/components/common/mixins/dateTime'

const chunk = (str) => {
	if (!str) return false
	const chunks = []
	let chunk = ''
	let counter = 0
	str.split(' ').forEach(s => {
		chunk = chunk + s + ' '
		counter = counter + s.length
		if (counter > 30) {
			chunks.push(chunk)
			chunk = ''
			counter = 0
		}
	})
	if (chunk.length) {
		chunks.push(chunk)
	}
	return chunks
}

export default {
	extends: Line,
	mixins: [dateTime],
	props: ['scores', 'grid'],
	data() { 
		return {
			options: {
				layout: {
					padding: {
						top: 7,
						bottom: 0,
						right: 21,
						left: 7,
					}
				},
				scales: {
					xAxes: [{
						type: 'time',
						time: {
							unit: 'day'
						},
						ticks: {
							display: false
						},
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						ticks: {
							suggestedMin: 0,
							suggestedMax: 4,
							display: false,
							maxTicksLimit: 5
						},
						gridLines: {
							display: true, //this.scores.length >= 10,
							color: '#e8e8e8',
							zeroLineColor: '#e8e8e8',
							drawTicks: false,
							drawBorder: false
						},
					}],
				},
				legend: {
					display: false
				},
				responsive: true,
				maintainAspectRatio: false,
				tooltips: {
					enabled: true,
					mode: 'nearest',
					intersect: false,
					titleFontFamily: 'Cabin',
					bodyFontFamily: 'Cabin',
					bodySpacing: 4,
					bodyFontSize: 11,
					displayColors: false,
					titleMarginBottom: 8,
					xPadding: 16,
					yPadding: 12,
					caretPadding: 7,
					callbacks: {
						
						title: (tooltipItem) => {
							return `${this.date(tooltipItem[0].label)}`
						},
						
						label: (ctx, data) => {
							const str = data.datasets[ctx.datasetIndex].data[ctx.index].label 
							return chunk(str)
						}
					}
				},				
				plugins: {
					datalabels: {
						display: false
					}

				}
			}
		}
	},
	methods: {
		parseData() {
			return this.scores.map(point => ({
				t: point.created,
				y: point.score,
				id: point.id,
				label: point.comments
			}))
		}
	},
	mounted() {
		const data = {
			datasets: [{
				borderColor: '#d82424',
				borderWidth: 4,
				fill: false,
				lineTension: 0.3,
				data: this.parseData()
			}]
		}
		this.renderChart(data, this.options)
	}
}
</script>

<style></style>