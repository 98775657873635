import { buildApi } from '@/services/api/factory'

const routes = {
	get: {
		fetchPortalLink: '/portalLink'
	},
	put: {
		updatePortalLink: '/portalLink'
	},
	post: {
		createPortalLink: '/portalLink',
		sendFeedback: '/feedback',
		teleHealth: '/teleHealth/client/${clientId}',
		upgrade: '/upgrade/request'
	},
	delete: {
		destroyPortalLink: '/portalLink'
	}
}

export const generalApi = buildApi('general', routes, '/clinician')

