<template>
<form :class='{ selected }' class='searchInput' @click.stop='focus' action='#' @submit='onSubmit'>
	<svg class='searchIcon smallIcon'>
		<use xlink:href='#search' />
	</svg>
	<input
		name='search'
		ref='input'
		type='search'
		v-model='localValue'
		v-autoGrow
		autocomplete='off'
		@keydown.enter='emitSubmit'
		@keydown.delete.stop
		@focus='onFocus'
		@blur='onBlur'
		:placeholder='placeholder'
		maxlength='80'
	/>
	<transition name='fade'>
		<svg 
			v-show='localValue' 
			v-tap='reset'
			class='smallIcon cross'
		>
			<use xlink:href='#cross' />
		</svg>
	</transition>
</form>
</template>

<script>
import { htmlEntities } from '@/utilities'
import { isMobile } from '@/utilities'

const getWidth = value => {
	if (value.length === 0) return '100%'
	const tmp = document.createElement('span')
	tmp.className = 'autoGrow'
	tmp.innerHTML = htmlEntities(value)
	document.body.appendChild(tmp)
	const w = tmp.getBoundingClientRect().width
	document.body.removeChild(tmp)
	return (w + 40) + 'px'
}

const setWidth = e => e.target.style.width = getWidth(e.target.value)

export default {
	name: 'SearchInput',
	props: ['placeholder','value'],
	directives: {
		autoGrow: {
			bind: function(el) {
				el.addEventListener('input', setWidth)
				el.style.width = getWidth(el.value)
			},
			unbind: function(el) {
				el.removeEventListener('input', setWidth)
			}
		}
	},
	data: () => ({
		isMobile: isMobile(),
		isChanged: false,
		selected: false,
		oldValue: null
	}),
	computed: {
		localValue: {
			get() {
				return this.value
			},
			set(value) {
				if (value !== this.oldValue) {
					this.oldValue = value
					this.emitInput(value)
				}
			}
		}
	},
	methods: {
		onFocus() {
			this.selected = true
		},
		onBlur() {
			if (this.isMobile && 'activeElement' in document) document.activeElement.blur()
			this.selected = false
		},
		onSubmit(e) {
			this.$refs.input.blur()
			e.preventDefault()
			return false
		},
		emitInput(value) {
			this.isChanged = true
			this.$emit('input', value)
		},
		emitSubmit() {
			if (this.isChanged) this.$emit('submit')
			this.isChanged = false
			setTimeout(()=>this.focus(), 0)
		},
		focus() {
			this.$refs.input.focus()
		},
		clear() {
			this.$refs.input.style.width = '100%'
			this.focus()
			this.emitInput('')			
		},
		reset(e) {
			e.preventDefault()
			this.clear()
			this.emitSubmit()
		}
	},
	mounted() {
		this.$nextTick(() => {
			this.$refs.input.style.width = getWidth(this.value)
		})
	}
}
</script>

<style lang='scss'>
.searchInput {
	display: flex;
	align-items: center;
	height: $size-control-height;
	background: $color-white;
	position: relative;
	overflow: hidden;

	&.selected {
		background: $color-focus;
	}

	> input {
		&::-webkit-search-decoration,
		&::-webkit-search-cancel-button,
		&::-webkit-search-results-button,
		&::-webkit-search-results-decoration {
			-webkit-appearance:none;
		}		
		height: 100%;
		flex: 0 0 auto;
		padding-left: $size-gutter * 4 + 16;
	}

	.searchIcon {
		position: absolute;
		top: 12px;
		left: 16px;
	}

	svg.cross {
		cursor: pointer;
		background: $button-gradient-primary;
		color: $color-white;
		border-radius: 8px;
		margin-left: $size-gutter;
		margin-right: $size-gutter * 2;
	}
}

span.autoGrow {
	height: 0;
	font-size: $size-font-standard;
	padding-left: $size-gutter * 2;
}
</style>


