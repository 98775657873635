<template>
<div class='clientsManager' :class='{ loaded }'>
	<template v-if='loaded'>
		<ClientsPanel />
		<ClientPanel />
	</template>
	<Loading v-else-if='loading' />
</div>
</template>

<script>
import Cookies from 'js-cookie'
import Loading from '@/components/common/Loading'
import ClientsPanel from './ClientsPanel'
import ClientPanel from './ClientPanel'

export default {
	name: 'ClientsManager',
	components: {
		Loading,
		ClientsPanel,
		ClientPanel
	},
	data: () => ({
		loaded: false
	}),
	computed: {
		clientDetail() {
			return +this.$route.params.clientId
		},
		loading() {
			return (
				this.$store.state.manageClients.apiPending || 
				this.$store.state.manageClientSets.apiPending || 
				this.$store.state.clientSetsPreview.apiPending
			)
		},
		selectedSets() {
			return this.$store.state.manageClientSets.selected
		},
		err() {
			return (
				this.$store.state.manageClientSets.apiError || 
				this.$store.state.manageClients.apiError ||
				this.$store.state.clientSetsPreview.apiError
			)
		},
		/*
		style() {
			return this.$store.state.profile.teamClients ? 'grid-template-columns: 10fr 9fr;' : 'grid-template-columns: 1fr 1fr;'
		}
		*/
	},
	methods: {
		refresh() {
			this.$store.dispatch('clientSetsPreview/reset')
				.then(() => this.$store.dispatch('manageClientSets/reset'))
				.then(() => this.$store.dispatch('manageClients/reset'))
				.then(() => this.$store.dispatch('manageClients/fetchTop'))
		}
	},
	watch: {
		selectedSets(key) {
			if (key) this.$store.dispatch('clientSetsPreview/setList', [key])
		},
		err(err) {
			if (err && err.code === 'ERRPERMISSION') this.refresh()
		},
		loading(value) {
			if (!value) this.loaded = true
		},
		clientDetail(a,b) {
			if (a && a!==b) {
				if (!this.$store.state.manageClients.list.includes(a)) 
					this.$store.dispatch('manageClients/fetchClient', a).then(() => {
						this.$store.commit('manageClients/setDetail', a)
						this.$store.dispatch('manageClients/clearSelected')
							.then(() => { 
								this.$store.dispatch('manageClients/toggleSelected', a) 
							})
					})	
				else {
					this.$store.commit('manageClients/setDetail', a)
					this.$store.dispatch('manageClients/toggleSelected', a)
				}
			}
		},
	},
	beforeRouteEnter (to, from, next) {
		next(vm => {
			vm.$store.dispatch('manageClients/clearSelected')
				.then(() => next())
		})
	},
	mounted() {
		const feedback = Cookies.getJSON(`${this.$store.state.profile.user.id}-clientFeedback`)
		const shared = Cookies.getJSON(`${this.$store.state.profile.user.id}-teamClients`)
		if (typeof feedback === 'boolean') this.$store.dispatch('manageClients/setFeedback', feedback)
		if (typeof shared === 'boolean') this.$store.dispatch('manageClients/setShared', shared)
		this.$store.dispatch('manageClients/fetchTop')
		this.$store.dispatch('clientSetsPreview/setList', this.selectedSets)
		if (
			this.clientDetail && this.clientDetail !== this.$store.state.manageClients.detail
		) {
			this.$store.dispatch('manageClients/fetchClient', this.clientDetail)	
				.then(() => {
					this.$store.dispatch('manageClients/toggleSelected', this.clientDetail)		
					this.$store.dispatch('manageClientSets/setClient',this.clientDetail)	
				})
		}
		else if (this.$store.getters['manageClients/detail']) {
			this.$store.dispatch('manageClientSets/setClient', this.$store.getters['manageClients/detail'].key)
			//if (this.$store.state.manageClients.clientView==='sets') this.$store.dispatch('manageClientSets/setClient', this.$store.getters['manageClients/detail'].key)
			if (this.$store.state.manageClients.clientView==='outcomes') this.$store.dispatch('manageClientOutcomes/fetchTop')
		}
		if (this.$route.params.linked === 'linked') {
			this.$store.dispatch('flash/showAction', 'emrLinked')
		}
	},
	destroyed() {
		if (this.$store.state.auth.authenticated) {
			this.$store.dispatch('clientSetsPreview/reset')
			this.$store.dispatch('manageClientSets/clearList')
		}
	}
}
</script>

<style lang='scss'>
.clientsManager {
	@include absTopLeft;
	@include fill;
	display: grid;
	grid-template-columns: 4fr 5fr;
	grid-gap: 1px;

	&.loaded {
		background: repeating-linear-gradient(
			180deg,
			darken($color-neutral, 5%),
			darken($color-neutral, 5%) 14px,
			$color-neutral-silver 14px,
			$color-neutral-silver 28px
		);
		background-position: 0 -1px;
	}

	.clientSetsPreview { border-right: none; }

	.loading { background: none; }
}
</style>
