<template>
<div class='setHistory'>
	<Points />
	<Point />
	<Footer @close='$emit("close")' />
	<transition name='fade'><Loading v-show='loading' /></transition>
</div>
</template>

<script>
import Loading from '@/components/common/Loading'
import Points from './Points'
import Point from './Point'
import Footer from './Footer'

export default {
	name: 'SetHistory',
	components: { Points, Point, Footer, Loading },
	props: ['setKey'],
	data: () => ({}),
	computed: {
		loading() { return this.$store.state.setHistory.apiPending },
		err() { return this.$store.state.setHistory.apiError }
	},
	methods: {
		fetch() {
			this.$store.dispatch('setHistory/fetchHistory', this.setKey)
		}
	},
	mounted() {
		this.fetch()
	},
	watch: {
		err(err) {
			if (err && err.code === 'ERRPERMISSION') {
				this.$emit('close')
			}
		}
	},
	beforeCreate() {
		this.$store.dispatch('setHistory/reset')
	}
}
</script>

<style lang='scss'>
.setHistory {
	position: relative;
	@include fill;
	display: grid;
	grid-template-columns: 5fr 4fr;
	grid-template-rows: 1fr auto;
	background: $color-neutral-shadow;

	.setHistoryPoints {
		grid-row: 1 / -1;
		border-right: 1px solid $color-neutral-shadow;
	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}
}
</style>
