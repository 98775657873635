<template>
<div class='checkButton' :class='{ checked }' @click.stop='$emit("click")'>
	<svg class='smallIcon'>
		<use v-if='checked' xlink:href='#check' />
		<use v-else xlink:href='#radio' />
	</svg>
</div>
</template>

<script>
export default {
	name: 'CheckButton',
	props: ['checked']
}
</script>

<style lang='scss'>
.checkButton {
	color: $color-neutral-dark;
	cursor: pointer;
	display: flex;
	align-items: center;

	&.checked {
		color: $color-black;
	}
}
</style>
