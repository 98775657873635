<template>
<div 
	class='clientSets'
	@mousedown='clickWithinStart'
	@click='e => clickWithinEnd(e, () => $store.dispatch("manageClientSets/clearSelected"))'
>
	<div class='resultsPanel'>
			<ClientFeedback v-if='!client.archived && $store.state.manageClients.feedback' :client='client' />			
		<!--
			<div v-if='!client.archived' class='createSetButton iconTextButton neutral tagPrimary' @click='createSet'>
				<svg class='smallIcon'>
					<use xlink:href='#plus' />
				</svg>
				<span class='label'>{{$t("elements.buttons.exerciseSet")}}</span>
			</div>
		-->
			<ResultInfo :total='totalSets' :foundPhrase='$t("elements.results.setsFound")' />
			<ClientSetsHeader v-if='totalSets' module='manageClientSets' />
			<div class='clientSetsWrapper'>
				<template v-for='itemKey in clientSetKeys'>
					<ClientSetRow :itemKey='itemKey' :key='itemKey' />
				</template>
			</div>
	</div>
</div> 
</template>

<script>
import { fill } from '@/utilities'	
import { setsApi } from '@/services/api/modules/clinician/sets'
import { clickWithin } from '@/components/common/mixins/clickWithin'
import { dateTime } from '@/components/common/mixins/dateTime'
import { smallModalMixin } from '@/components/common/mixins/modal'
import ConfirmModal from '@/components/common/modals/Confirm'
import ResultInfo from '@/components/common/ResultInfo'
import ClientSetsHeader from './ClientSetsHeader'
import ClientSetRow from './ClientSetRow'
import ClientFeedback from './ClientFeedback'

export default {
	name: 'clientSets',
	mixins: [clickWithin, dateTime, smallModalMixin],
	components: {
		ResultInfo,
		ClientSetsHeader,
		ClientSetRow,
		ClientFeedback
	},
	props: ['client'],
	data() { return { creating: false }},
	computed: {
		loading() { 
			return (
				this.$store.state.manageClientSets.apiPending || 
				this.$store.state.sets.apiPending ||
				this.$store.state.clientSetsPreview.apiPending ||
				this.creating
			) 
		},
		clientSetKeys() { return this.$store.state.manageClientSets.list },
		totalSets() { return this.$store.state.manageClientSets.meta.total }
	},
	methods: {
		fetchTop() { this.$store.dispatch('manageClientSets/fetchTop') },
		fetchNext() { this.$store.dispatch('manageClientSets/fetchNext') },
		createSet() {
			const fn = async () => {
				this.creating = true
				const [err, result] = await setsApi.touch('createSet', { type: 'clientSet', clientId: this.client.id, savename: this.date(new Date()) })
				this.creating = false
				if (!err) {
					this.$store.dispatch('setBuilder/fetch', result.id)
						.then(() => this.$router.replace({ name: 'designer' }))
				}
			}
			this.showSmallModal(ConfirmModal, {
				title: this.$t('views.createClientSet.title'),
				message: [
					fill(this.$t('views.createClientSet.p1'), { client: `${this.client.firstname} ${this.client.lastname}`}), 
					this.$t('views.createClientSet.p2'),
					this.$t('views.createClientSet.p3')
				],
				ok: this.$t('elements.buttons.ok'),
				fn
			})
		}
	},
	watch: {
		client(newClient, oldClient) {
			if (newClient.key && newClient.key !== oldClient.key) {
				this.$store.dispatch('manageClientSets/setClient', newClient.key) 
				this.$store.dispatch('manageClientSets/clearSelected')
			}
		},
		loading(v) {
			this.$emit('working', v)
		}
	},
	mounted() {
		if (this.client && this.client.key !== this.$store.state.manageClientSets.client) {
			this.$store.dispatch('manageClientSets/setClient', this.client.key)
		}
	}
}
</script>

<style lang='scss'>
.clientsManager .clientSets {

	&.showPreview {
		display: grid;
		grid-template-columns: 1fr minmax(225px, 37%);

		.clientSetRow .updatedCol { display: none; }
		.tableColumns > div:nth-child(2) { display: none; }
	}

	.resultsPanel {
		position: relative;
	}

	.clientSetsWrapper {
		margin: 0 $size-gutter * 2;
		&:not(:empty) {
			border-bottom: 1px solid $color-neutral-shadow;
		}
	}

	.createSetButton { 
		margin: $size-gutter * 2; 
		box-sizing: content-box;
		border: 1px solid transparent;
		background: none;
	}

	.loading { background: rgba($color-neutral-panel, 0.3); }
}
</style>
