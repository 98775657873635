<template>
<div class='setsPreview clientSetsPreview'>

	<div v-if='preview' class='preview' :style='`height: ${Math.min(272, (preview.elements.length * 54)+2)}px`'><Scroll>
		<div 
			v-for='(element, index) in preview.elements'
			:key='index'
			:class='{ divider: element.type === "setDivider" }'
			class='element bubble'
		>
			<LazyImage v-if='element.thumbnail' :src='element.thumbnail' class='thumbnail' :contain='true' />
			<div class='content'><div>
				<div class='elementTitle'>{{element.title}}</div>
				<div class='parameters'>
					<span
						v-for='(parameter, index) in element.parameters'
						:key='index'
						class='parameter'
					><b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' /></span>
				</div>
			</div></div>
		</div>
	</Scroll></div>
</div>
</template>

<script>
import Scroll from '@/components/common/Scroll'
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'ClientSetsPreview',
	props: ['setKey'],
	components: {
		LazyImage,
		Scroll
	},	
	computed: {
	//	loading() { return this.$store.state.clientSetsPreview.apiPending },
		preview() { return this.$store.getters['clientSetsPreview/previews'].find(preview => preview.key === this.setKey) }
	},
	methods: {
		load() {
			this.$store.dispatch('setBuilder/fetch', this.$store.state.manageClientSets.selected)
				.then(() => this.$router.replace({ name: 'designer' }))
		}
	},
	watch: {
		preview(v) {
			if (v) this.$emit('previewVisible')
		}
	},
	mounted() {
		if (this.preview) this.$emit('previewVisible')
	}
}
</script>

<style lang='scss'>
.clientSetsPreview {
	position: relative;

	.preview {
		position: relative;
		width: 100%;
		border: 1px solid $color-neutral-shadow;
		background: $color-white;
		min-height: 138px;
	}

	.setTitle {
		display: flex;
		align-items: center;
		height: $size-control-height;
		font-size: $size-font-standard;
		font-weight: bold;
		padding-left: $size-gutter * 2;
		background: $color-neutral-accent;

		.closeButton {
			margin-left: auto;
		}
	}

	.element {
		max-height: 54px;
		overflow: hidden;
		background: $color-white;
		&.divider {
			background: $color-neutral-accent;
		}
	}

	.content {
		flex: 1;
		overflow: hidden;
		display: flex;
		align-items: center;

		> div {
			overflow: hidden;
		}
	}

	.thumbnail {
		width: 58px;
		height: 54px;
	}

	.elementTitle {
		font-size: $size-font-standard;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.parameters {
		font-size: $size-font-small;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.parameter {
			margin-right: $size-gutter;
			padding-left: $size-gutter;
			border-left: 1px solid $color-neutral;

			&:first-child {
				padding-left: 0;
				border-left: none;
			}
		}
	}

	.loading {
		background: rgba($color-neutral-panel, 0.3);
	}

}
</style>