<template>
<div class='setHistoryPoints'>

	<div class='header'>
		{{$t('views.setHistory.title')}}
	</div>

	<div class='content'>
		<ScrollForever
			:itemHeight='40'
			:itemKeys='$store.state.setHistory.list'
			:itemComponent='itemComponent'
		>
			<template slot='header'></template>
			<template slot='sticky'></template>
		</ScrollForever>
	</div>

</div>
</template>

<script>
import ScrollForever from '@/components/common/ScrollForever'
import PointRow from './PointRow'

export default {
	name: 'SetHistoryPoints',
	components: { ScrollForever },
	props: [],
	data: () => ({
		itemComponent: PointRow
	}),
	computed: {},
	methods: {}
}
</script>

<style lang='scss'>
.setHistoryPoints {
	@include fill;
	display: grid;
	grid-template-rows: auto 1fr;
	background: $color-neutral-panel;

	.header {
		display: flex;
		padding: $size-gutter * 2;
		align-items: center;
		justify-content: center;
		font-size: $size-font-large;
		margin: $size-gutter * 2 0;
		font-weight: bold;
	}

	.content {
		position: relative;
	}

	.vue-recycle-scroller__item-wrapper {
		&:before {
			position: absolute;
			top: 0;
			left: $size-gutter * 2;
			width: calc(100% - #{$size-gutter * 4});
			height: 1px;
			background: $color-neutral-shadow;
			content: '';
			z-index: 10;
		}
	}
}
</style>
