<script>
import 'chartjs-plugin-datalabels'
import { Line } from 'vue-chartjs'

export default {
	extends: Line,
	mixins: [ ],
	props: ['scores', 'grid'],
	data() { 
		return {
			options: {
				layout: {
					padding: {
						top: 28,
						bottom: 21,
						right: 35,
						left: 35,
					}
				},
				scales: {
					xAxes: [{
						type: 'time',
						time: {
							unit: 'day'
						},
						ticks: {
							display: false
						},
						gridLines: {
							display: false
						}
					}],
					yAxes: [{
						ticks: {
							suggestedMin: 0,
							suggestedMax: 10,
							display: false
						},
						gridLines: {
							display: false, //this.scores.length >= 10,
							color: '#e8e8e8',
							zeroLineColor: '#e8e8e8',
							drawTicks: false,
							drawBorder: false,
						},
					}],
				},
				legend: {
					display: false
				},
				tooltips: {
					enabled: false
				},
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					datalabels: {
						formatter: value => value.y,
						display: context => {
							return this.scores.length >= 10 ? context.dataIndex === 0 || context.dataIndex === this.scores.length - 1 : true
						},
						backgroundColor: '#d82424',
						borderRadius: 2,
						padding: { top: 6, right: 8, bottom: 4, left: 8 },
						color: '#ffffff',
						anchor: 'center',
						align: 'center',
						font: {
							family: 'Cabin',
							size: 12,
							weight: 'bold'
						}
					}
				}
			}
		}
	},
	methods: {
		parseData() {
			return this.scores.map(point => ({
				t: point.created,
				y: point.score,
				id: point.id
			}))
		}
	},
	mounted () {
		const data = {
			datasets: [{
				borderColor: '#d82424',
				borderWidth: 4,
				fill: false,
				lineTension: 0.3,
				data: this.parseData()
			}]
		}
		this.renderChart(data, this.options)
	}
}
</script>

<style></style>