<template>
	<div class='setPreview'>

		<div class='header'>
			<div>Exercises from: <b>{{date(preview.created)}}</b></div>
			<div><span v-if='latest' class='miniButton success'>{{$t('elements.labels.latest')}}</span></div>
		</div>

		<div v-if='preview.title || preview.instructions' class='coversheet'>
			<div v-if='preview.title' class='title'>{{point.title}}</div>
			<div v-if='preview.instructions' class='instructions' v-html='preview.instructions' />
		</div>

		<div class='elements'>
			<template v-for='(element, index) in preview.elements'>

				<div v-if='element.type === "setExercise"' class='exercise element' :key='index'>
					<div class='title'>{{element.title}}</div>
					<div v-if='element.parameters.length' class='parameters'>
						<span
							v-for='(parameter, index) in element.parameters'
							:key='index'
							class='parameter'
						><b>{{parameter.title}}:</b>&nbsp; <span v-html='parameter.value' /></span>
					</div>
					<div v-if='element.resources.length' class='resources'>
						<div
							v-for='(resource, index) in element.resources'
							:key='index'
							class='resource'
						>
							<div class='resourceImgContainer'><div><LazyImage v-if='resource.url' :src='resource.url' /></div></div>
							<div v-if='resource.caption' class='caption' v-html='resource.caption' />
						</div>
					</div>
					<div v-if='element.instructions' class='instructions' v-html='element.instructions' />
				</div>

				<div v-else-if='element.type === "setDivider"' class='divider element' :key='index'>
					<div class='title'>{{element.title}}</div>
					<div v-if='element.instructions' class='instructions' v-html='element.instructions' />
				</div>

			</template>
		</div>

	</div>
</template>

<script>
import { accumulate } from '@/store/helpers'
import { dateTime } from '@/components/common/mixins/dateTime'
import LazyImage from '@/components/common/LazyImage'

export default {
	name: 'SetPreview',
	mixins: [dateTime],
	components: { LazyImage },
	props: {
		exerciseSet: {
			type: Object,
			default: () => ({}),
		},
		resources: {
			type: Array,
			default: () => ([])
		},
		latest: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		preview() {
			const elements = this.exerciseSet.elements.map(element => {
				const parsed = { type: element.type, title: element.title, instructions: element.instructions }
				if (element.type === 'setExercise') {
					parsed.parameters = element.parameters.filter(parameter => parameter.title && parameter.value)
					parsed.resources = element.resources.map(resource => {
						const url = resource.resourceId ? this.previewResources[resource.resourceId].url : null
						return { caption: resource.caption, url }
					})
				}
				return parsed
			})
			return {
				id: this.exerciseSet.id,
				created: this.exerciseSet.created,
				title: this.exerciseSet.title,
				instructions: this.exerciseSet.instructions,
				elements
			}			
		},
		previewResources() {
			return accumulate(this.resources)
		}
	}
}
</script>

<style lang='scss'>
.setPreview {
	position: relative;
	padding: $size-gutter * 4;
	background: $color-white;

/*
	.elementTitle {
		font-size: $size-font-standard;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.parameters {
		font-size: $size-font-small;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		.parameter {
			margin-right: $size-gutter;
			padding-left: $size-gutter;
			border-left: 1px solid $color-neutral;

			&:first-child {
				padding-left: 0;
				border-left: none;
			}
		}
	}
*/

	.header {
		display: flex;
		align-items: center;
		font-size: $size-font-large;
		font-weight: bold;
		b { color: $color-primary-accent; }
		margin-bottom: $size-gutter * 2;

		> div:last-child {
			margin-left: auto;
		}
	}

	.coversheet {
		background: $color-white;
		padding: $size-gutter * 4;
		margin-bottom: 0;

		.title {
			font-size: $size-font-large;
			font-weight: bold;
			text-align: center;
			text-decoration: underline;
		}

		> div:nth-child(2) {
			margin-top: $size-gutter * 2;
		}
	}

	.elements {
	}

	.element {
		margin-bottom: 1px;
		padding: $size-gutter * 4 0;

		&.exercise {
			background: $color-white;
		}

		&.divider {
			background: $color-neutral-silver;
			border-top: $size-gutter solid $color-primary-accent;
			margin-top: $size-gutter * 2;

			&:first-child { margin-top: 0; }
		}

		.title {
			font-size: $size-font-large;
			font-weight: bold;
			color: $color-primary;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.parameters {
			font-size: $size-font-standard;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin-top: $size-gutter;

			.parameter {
				margin-right: $size-gutter;
				padding-left: $size-gutter;
				border-left: 1px solid $color-neutral;

				&:first-child {
					padding-left: 0;
					border-left: none;
				}
			}
		}

		.resources {
			padding: 1px;
			display: grid;
			grid-gap: 1px;
			grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
			margin-top: $size-gutter * 2;

			.resource {
				background: $color-white;
				box-shadow: 0 0 0 1px $color-neutral-shadow;
			}

			.caption {
				text-align: center;
				background: white;
				padding: $size-gutter;
			}

			.resourceImgContainer {
				width: 100%;
				padding-top: 75%;
				position: relative;

				> div {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}

		.instructions {
			margin-top: $size-gutter * 2;
		}
	}

	.exercise {
		border-bottom: 1px dashed $color-neutral-shadow;
	}

	.instructions {
		@include rich;
	}
}
</style>