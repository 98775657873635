<template>
<SmallModal class='emailExistsModal' @close='$emit("close")' @doDefault='doFn'>
	<template v-slot:title>{{$t("views.clientEmailExists.title")}}</template>
	<template v-slot:message>
		<p>{{$t("views.clientEmailExists.p1")}}</p>
		<p class='email'><b>{{email}}</b></p>
		<p>{{$t("views.clientEmailExists.p2")}}</p>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='doFn'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t("elements.buttons.ok")}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import SmallModal from '@/components/common/modals/SmallModal'

export default {
	components: {
		SmallModal
	},
	props: ['email', 'fn'],
	data: () => ({ fnDone: false }),
	methods: {
		doFn() {
			if (this.fnDone) return
			this.fnDone = true
			this.$emit('close')
			if (typeof this.fn === 'function') this.fn()
		}
	}
}
</script>

<style lang='scss'>
.emailExistsModal {
	.email { padding-left: $size-gutter * 2;}
}
</style>