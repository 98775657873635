<template>
<EnabledButton @click='$emit("click")' :disabled='disabled' :svg='"#history"' :label='$t("elements.buttons.history")' class='neutral' />
</template>

<script>
import EnabledButton from './Enabled'

export default {
	name: 'HistoryButton',
	props: ['disabled'],
	components: {
		EnabledButton
	}
}
</script>