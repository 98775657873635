<template>
<div class='resultInfo'>
	<div>
		<div v-if='searched' class='search'><b>{{$t('elements.results.searched')}}</b> &quot;{{searched}}&quot;</div>
		<div v-if='total !== undefined' class='total'><b>{{ totalFound }}</b>&nbsp; {{total}}</div>
	</div>
</div>
</template>

<script>
export default {
	name: 'ResultInfo',
	props: ['searched', 'total', 'readOnly', 'foundPhrase'],
	computed: {
		totalFound() {
			return this.foundPhrase || this.$t('elements.results.totalFound')
		}
	}
}
</script>

<style lang='scss'>
.resultInfo {
	display: flex;
	font-size: $size-font-standard;
	padding-left: $size-gutter * 4;
	padding-right: $size-gutter * 4;
	align-items: bottom; 
	justify-content: left;
	margin-bottom: $size-gutter * 2;

	> div {
	}

	> div:first-child {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: left;
		margin-top: auto;

		> div {
			padding-top: 11px;
			padding-bottom: 0px;
		}
		.search { padding-right: $size-gutter * 2; }
	}

	.search, .total {
		white-space: nowrap !important;
	}

	.total { 
		display: flex;
		align-items: center;
		justify-content: left;
	}

	.dash {
		margin-right: $size-gutter;
		margin-left: $size-gutter;
	}

	.locked {
		display: flex;
		align-items: center;
		margin-left: $size-gutter * 2;
		font-size: $size-font-standard; 
		color: $color-alert;
		svg {
			margin-left: $size-gutter * 2;
		}
	}
}
</style>