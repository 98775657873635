<template>
<div class='clientPanelTabs tabs'>
	<div 
		@click='$store.dispatch("manageClients/setSetsView")'
		class='textButton'
		:class='{ selected: $store.state.manageClients.clientView === "sets" }'
	>
		<span class='label'>{{ $t('elements.tabs.exerciseSets') }}</span>
	</div>
	<div 
		@click='$store.dispatch("manageClients/setLogsView")'
		class='textButton'
		:class='{ selected: $store.state.manageClients.clientView === "logs" }'
	>
		<span class='label'>Client Activity</span>
	</div>
	<div 
		@click='$store.dispatch("manageClients/setOutcomesView")'
		class='iconTextButton'		
		:class='{ selected: $store.state.manageClients.clientView === "outcomes" }'
	>
		<div v-if='newOutcome' class='smallIcon newOutcome' />
		<span class='label'>{{ $t('elements.tabs.outcomeMeasures') }}</span>
	</div>
</div>
</template>

<script>
export default {
	name: 'ClientPanelTabs',
	props: ['client'],
	computed: {
		newOutcome() {
			const notifications = Object.values(this.$store.state.notifications.notifications)
			return !!notifications.find(n=>n.clientId===this.client.id && n.type==='clientOutcomeCompleted' && n.viewed===false)
		}
	}
}
</script>

<style lang='scss'>
.clientPanelTabs {
	position: sticky;
	top: -1px;
	z-index: 20;
	.label span {
		margin-left: $size-gutter * 2;
	}

	display: flex;
	margin: 0 $size-gutter * 2;
	cursor: pointer;
	> div:not(.closeButton) { 
		height: $size-control-height + 2px;
		flex: 1; 
		&.selected {
			background: linear-gradient(to bottom, $color-neutral-silver, $color-neutral-panel);
			border: 1px solid $color-neutral-shadow;
			border-bottom: none;
			& + div { border-left: none; }
		}
		&:not(.selected) {
			border: 1px solid $color-neutral-shadow;
			border-top: none;
			background: linear-gradient(to bottom, $color-neutral-panel, $color-neutral);
/*
			&:first-child { border-right: none; }
			&:not(:first-child) { border-left: none; }
*/
			
			&:not(:last-child) { border-right: none; }

			& + div:not(.selected) { border-left: none; 
			}

		}
	}
	
	.newOutcome { width: 12px; height: 12px; border-radius: 50%; background: $button-gradient-alert; margin-right: $size-gutter; min-width: 12px; }

}
</style>
