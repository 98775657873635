<template>
<SmallModal class='clientModal' @close='$emit("close")' @doDefault='save'>
	<template v-slot:title>{{title}}</template>
	<template v-slot:message>
		<div v-if="client && client.emailErr" class="bounced">
			We are having trouble delivering emails to {{client.email}}. It looks like this email address is not active or is not accepting emails from SimpleSet.<br /><br />

			To ensure your message gets through, please use an alternative email address, review that your client consents to receive emails from SimpleSet, and educate them not to mark SimpleSet emails as spam.
		</div>
			<div class='field'>
				<div class='label'>
					{{$t('elements.labels.lastname')}}:
					<svg class='detail' @click='detailLastname'>
						<use xlink:href='#detail' />
					</svg>
				</div>
				<TextInput 
					v-model='lastname' 
					maxlength='40' 
					ref='lastname'
					:placeholder='$t("views.client.phLastname")' 
					:class='{ err: errLastname }'
					@input='errLastname = false'
				/>
			</div>
			<div class='field'>
				<div class='label'>{{$t('elements.labels.firstname')}}:</div>
				<TextInput 
					v-model='firstname' 
					maxlength='40' 
					:placeholder='$t("views.client.phFirstname")' 
					:class='{ err: errFirstname }'
					@input='errFirstname = false'
				/>
			</div>
			<div class='field'>
				<div class='label'>{{$t('elements.labels.email')}}:</div>
				<TextInput 
					v-model='email' 
					maxlength='100' 
					ref='email'
					:placeholder='$t("views.client.phEmail")' 
					:class='{ err: errEmail }'
					@input='errEmail = false'
					@focus='errEmail = false'
				/>
			</div>
			<div class='field'>
				<div class='label'>
					{{$t('elements.labels.portalKey')}}:
				</div>
				<TextInput 
					v-model='portalKey' 
					maxlength='4' 
					ref='portalKey'
					:numeric='true'
					:placeholder='$t("views.client.phPortalKey")' 
					:class='{ err: errPortalKey }'
					@input='errPortalKey = false'
					@focus='portalKeyFocussed = true'
					@blur='portalKeyFocussed = false'
				/>
			</div>
			<div class='portalKeyWarning' :class='{ portalKeyFocussed }' v-html='$t("views.client.portalKeyWarning")' />
			<div v-if='teams.length' class='field teamsField' :class='{ highlight: teams.length && !teamIds.length}'>
				<div class='label'>Share with:</div>
				<div class='teams'>
					<div 
						v-for='(team, index) in teams'
						:key='index'
						@click='toggleTeam(team.id)'>
						<CheckButton :checked='teamIds.includes(team.id)' />
						<span class='label'>{{team.title}}</span>
					</div>	
				</div>
			</div>
			<div class='field'>
				<div class='label'>{{$t('elements.labels.language')}}:</div>
				<LanguageInput v-model='lang' />
			</div>
			<transition name='fade'><Loading v-show='loading' /></transition>
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='save'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{$t('elements.buttons.save')}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import { isEmpty } from 'lodash'
import { isEmail } from '@/utilities'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import { smallModalMixin } from '@/components/common/mixins/modal'
import SmallModal from '@/components/common/modals/SmallModal'
import AlertModal from '@/components/common/modals/Alert'
import ConfirmModal from '@/components/common/modals/Confirm'
import EmailExistsModal from './EmailExists'
import TextInput from '@/components/common/TextInput'
import LanguageInput from '@/components/common/LanguageInput'
import Loading from '@/components/common/Loading'
import CheckButton from '@/components/common/buttons/Check'

export default {
	mixins: [smallModalMixin],
	components: {
		SmallModal,
		TextInput,
		LanguageInput,
		Loading,
		CheckButton
	},
	props: ['client', 'listModule', 'emailRequired', 'onSave'],
	data() {
		return {
			id: null,
			firstname: '', 
			lastname: '', 
			email: '', 
			portalKey: null, 
			lang: 'en',
			errFirstname: false, 
			errLastname: false, 
			errEmail: false, 
			errPortalKey: false,
			loading: false,
			portalKeyFocussed: false,
			teamIds: []
		}
	},
	computed: {
		title() { return this.id ? this.$t('views.client.titleEdit') : this.$t('views.client.titleAdd') },
		portalKeyStr() { return typeof this.portalKey === 'number' ? this.portalKey+'' : null },
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		}
	},
	methods: {
		showErrs(err) {
			this.loading = false
			if ('firstname' in err) this.errFirstname = true
			if ('lastname' in err) this.errLastname = true
			if ('email' in err) this.errEmail = true
			if ('portalKey' in err) this.errPortalKey = true
		},
		validate() {
			const errs = {}
			if (!this.lastname) errs.lastname = true
			if (this.errEmail) errs.email = true
			if (this.emailRequired && !this.email) errs.email = true
			if (this.email && !isEmail(this.email)) errs.email = true
			if (this.client?.emailErr && this.email.trim()===this.client.email) {
				errs.email = true 
				this.showSmallModal(AlertModal, {
	                title: this.$t('Choose a different email address.'),
	                message: this.$t(`Messages cannot be sent to ${this.email}.`)
	            })
			}
			if (
				(this.emailRequired && (!this.portalKeyStr || this.portalKeyStr.length !== 4)) ||
				(!this.emailRequired && this.portalKeyStr !== null && this.portalKeyStr.length && this.portalKeyStr.length !== 4)
			) errs.portalKey = true
			return errs					
		},
		async save() {
			const fn = async () => {
				this.loading = true
				const payload = {
					firstname: this.firstname,
					lastname: this.lastname,
					email: this.email || null,
					portalKey: this.portalKeyStr || null,
					lang: this.lang,
				}
				if (this.teams && this.teams.length) {
					payload.joinTeamIds = this.teamIds
					if (this.id) payload.unjoinTeamIds = this.teams.filter(t=>!this.teamIds.includes(t.id)).map(t=>t.id)
				}
				if (this.id) payload.id = this.id
				const [err, result] = await clientsApi.touch(payload.id ? 'update' : 'save', payload)
				if (err && err.statusCode === 400) {
					this.showErrs(err.details.body.keys.reduce((a,b)=> (a[b]=true,a),{}))
				} else if (err) {
					this.$emit('close')
				} else {
					if (this.id) {
						const client = result
						if (!client.email) client.email = ''
						if (!client.portalKey) client.portalKey = ''
						this.$store.dispatch('clients/parseOver', client)
					} else if (this.listModule) {
						await this.$store.dispatch(this.listModule + '/resetSearch')
						await this.$store.dispatch(this.listModule + '/fetchTop')
						this.$store.dispatch(this.listModule + '/setSelected', result.id)
					}
					this.$emit('close')
					if (typeof this.onSave === 'function') this.onSave(result)
				}
			}
			if (this.loading) return
			const errs = this.validate()
			if (!isEmpty(errs)) return this.showErrs(errs)
			if (this.email && !this.id) await this.checkEmail(fn)
			else if (this.id && (this.firstname !== this.client.firstname || this.lastname !== this.client.lastname)) this.confirmUpdate(fn)
			else await fn()
		},
		async checkEmail(fn) {
			this.loading = true
			const [err, result] = await clientsApi.touch('fetchClients', { search: this.email })
			if (err) {
				this.showErrs({ email: true })
			} else if (result.total) {
				this.loading = false
				this.showSmallModal(EmailExistsModal, { email: this.email, fn })
			} else {
				await fn()
			}
		},
		toggleTeam(teamId) {
			const i = this.teamIds.indexOf(teamId)
			if (i===-1) {
				this.teamIds.push(teamId)
			} else {
				this.teamIds.splice(i, 1)
			}
		},
		confirmUpdate(fn) {
			this.showSmallModal(ConfirmModal, {
				title: this.$t('views.confirmClientUpdate.title'),
				message: [this.$t('views.confirmClientUpdate.p1'), this.$t('views.confirmClientUpdate.p2')],
				ok: this.$t('elements.buttons.overwrite'),
				fn
			})
		},
		detailLastname() {
            this.showSmallModal(AlertModal, {
                title: this.$t('views.detailClientName.title'),
                message: this.$t('views.detailClientName.p1')
            })
		}
	},
	mounted() {
		if (this.value) this.textInput = this.value

		if (this.client) {
			if (this.client.id) this.id = this.client.id
			if (this.client.firstname) this.firstname = this.client.firstname
			if (this.client.lastname) this.lastname = this.client.lastname
			if (this.client.email) this.email = this.client.email
			if (this.client.emailErr) this.errEmail = true
			if (this.client.portalKey) this.portalKey = +this.client.portalKey
			if (this.client.lang) this.lang = this.client.lang
			if (this.client.teamIds) this.teamIds = [...this.client.teamIds]
		} else if (this.teams.length) {
			this.teamIds=this.teams.map(t=>t.id)
			/*
			if (this.teams.length===1) {
				this.teamIds=[this.teams[0].id]
			} else if (this.$store.state.manageClients.teamId) {
				this.teamIds=[this.$store.state.manageClients.teamId]
			}
			*/
		}

		this.$nextTick(()=>{
			if (this.emailRequired) {
				if (this.lastname && !this.email) this.$refs.email.focus()
				else if (this.lastname && this.email && !this.portalKey) this.$refs.portalKey.focus()
				else this.$refs.lastname.focus()
			}
			else this.$refs.lastname.focus()
		})

	}
}

/*
Old birthday field:
<div class='field'>
	<div class='label'>
		{{$t('elements.labels.birthday')}}:
	</div>
	<DateInput 
		v-model='birthday' 
		maxlength='100' 
		:initialView='birthday ? "day" : "year"'
		:class='{ err: errBirthday }'
		@input='errBirthday = false'
	/>
</div>
*/
</script>

<style lang='scss'>
.clientModal {

	.formContent {

	}

	.loading {
		background: rgba($color-white, 0.3);
	}

	.languageInput {
		flex: 1;
	}

	.bounced {
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard;
		color: $color-alert;
		padding-bottom: $size-gutter * 3;
	}

	.portalKeyWarning {
		padding: $size-gutter * 2;
		padding-left: 100px + $size-gutter * 2;
		flex-wrap: wrap;
		font-size: $size-font-small;
		line-height: $size-lineHeight-standard;

		b { color: $color-primary-accent; }

		&.portalKeyFocussed { color: $color-alert; b { color: $color-alert; }}
	}

	.notSharedWarning {
		padding: $size-gutter * 2;
		padding-left: 100px + $size-gutter * 2;
		font-size: $size-font-small;
		line-height: $size-lineHeight-standard;
		color: $color-alert;	
	}

	.teamsField.highlight * { color: $color-alert !important; }

	.teams {
		display: flex;
		align-items: center;
		justify-content: left;
		padding-left: $size-gutter * 2;
		column-gap: $size-gutter * 2;

		> div {
			display: flex;
			align-items: center;
			min-height: 24px;				
			font-size: $size-font-standard;
			cursor: pointer;				
			.checkButton { margin-right: $size-gutter; pointer-events: none; }
		}
	}	
}
</style>