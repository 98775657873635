<template>
<div class='lazy' v-on='$listeners'>
	<img loading='lazy' :src='src' :class='{ loaded, contain }' @load='loaded = true' />
</div>
</template>

<script>
export default {
	name: 'LazyImage',
	props: ['src', 'contain'],
	data: () => ({
		loaded: false
	}),
	mounted() {
		if (this.src && this.src.includes('data:')) {
			this.loaded = true
			this.onScreen = true
		}
	}
}
</script>

<style lang='scss'>
.lazy {
	width: 100%;
	height: 100%;
	position: relative;

	img {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
		transition: opacity .2s linear;
		opacity: 0;
		pointer-events: none;

		&.loaded {
			opacity: 1;
		}

		&.contain {
			object-fit: contain;
		}
	}
}
</style>