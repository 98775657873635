<template>		
<RecycleScroller
	:items='itemKeys'
	:item-size='itemHeight'
	:getSelected='getSelected'
	:setSelected='setSelected'
	:setSelecting='setSelecting'
	:buffer='500'
	class='recycleScroller'
>
	<template slot='before'>
		<slot name='header' />
	</template>
	<template slot='sticky'>
		<slot name='sticky' />
	</template>
	<template slot='inner'>
		<slot name='inner' />
	</template>	
	<template v-slot='{ item }'>
		<component :is='itemComponent' :itemKey='item' @showContextMenu='e=>$emit("showContextMenu", e)' />
	</template>
	<template slot='after'>
		<slot name='footer' />
		<AtBottom :action='atBottom' />
	</template>
</RecycleScroller>
</template>

<script>
import RecycleScroller from '@/libs/vueVirtualScroller/components/RecycleScroller'
import AtBottom from '@/components/common/AtBottom'

export default {
	name: 'ScrollForever',
	components: {
		RecycleScroller,
		AtBottom
	},
	props: ['itemComponent', 'itemKeys', 'itemHeight', 'fetchNext', 'loading', 'getSelected', 'setSelected', 'setSelecting'],
	computed: {},
	methods: {
		atBottom() {
			if (!this.loading && typeof this.fetchNext === 'function') this.fetchNext()
		},
		scrollToTop: function() {
			const scrollContent = this.$el.querySelectorAll('.scrollContent')[0]
			if (scrollContent) scrollContent.scrollTop = 0
		}
	}
}
</script>

<style lang='scss'>
.recycleScroller {
	position: absolute !important;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	.vue-recycle-scroller__item-wrapper {
		position: relative;
		&:after {
			position: absolute;
			bottom: 0;
			left: $size-gutter * 2;
			width: calc(100% - #{$size-gutter * 4});
			height: 1px;
			background: $color-neutral-shadow;
			content: '';
		}
	}
	
}

.desktop .recycleScroller .scrollContainer {

	color: $color-neutral-panel;
	transition: color linear 200ms;
	&:hover { &::-webkit-scrollbar { display: block; } } 

	> div { color: $color-black; }

	&::-webkit-scrollbar {
		display: none;
		width: $size-gutter * 2;
		cursor: move;
	}

	&::-webkit-scrollbar-track {}
	&::-webkit-scrollbar-thumb {
		box-shadow: inset 0 0 10px 10px;
		border: solid 3px transparent;
		border-radius: $size-gutter;
		color:  darken($color-neutral, 12%);
	}	


	&::-webkit-resizer,
	&::-webkit-scrollbar-button,
	&::-webkit-scrollbar-corner { display: none; }

	scrollbar-width: thin;		
}
</style>