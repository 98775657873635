<template>
<div class='clientRow' :class='{ selected }' @click.stop='onClick' @mousedown='onMouseDown' @dblclick.stop='onDblClick'>
	<div class='lastnameCol'>
		<svg v-if="client.emailErr && !selected" class='emailErr smallIcon' @click='toggleSelected'>
			<use xlink:href='#alert' />
		</svg>

		<CheckButton 
			v-else
			@click.native='toggleSelected'
			:checked='selected' 
		/>
		<span>
			<span>
				<span :class='{ alert: typeof client.feedbackScore === "number" && client.feedbackScore <= 1 }'>{{client.lastname}}, {{client.firstname}}</span><br />
				<span class="email" :class="{ emailErr: client.emailErr }">{{client.email}}</span>
			</span>
			<img v-if='$store.state.manageClients.feedback && typeof client.feedbackScore === "number"' class='feedback' :src='`/img/faces/${client.feedbackScore}.png`' width="1" height="1" />
			<svg v-if='newOutcome' class='smallIcon newOutcome'>
				<use xlink:href='#outcomes' />
			</svg>

		</span>

		<div class='tags'>
			<!--
				<span 
					v-for='(teamId, index) in clientTeams'
					:key='index'
					class='tag'
				>{{$store.state.profile.teams[teamId].title}}</span>
			-->
			<span v-if='client.emr' class='tag success'>{{$store.state.profile.emr.title.charAt(0).toUpperCase() + $store.state.profile.emr.title.slice(1)}}</span>
			<span v-if='teams.length && (!client.teamIds || !client.teamIds.length)' class='tag alert'>Not Shared</span>
		</div>	
	</div>
	<!--
	<div class='emailCol'>
		<span>{{client.email}}</span>		
	</div>
-->
	<div class='clinicianCol'>
		<span>{{client.clinicianName}}</span>		
	</div>
<!--	<div class='setCountCol'>{{client.setCount}}</div> -->
<!--	<div class='createdCol'>{{created}}</div> -->
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { smallModalMixin } from '@/components/common/mixins/modal'
import CheckButton from '@/components/common/buttons/Check'
import ClientModal from '@/components/clinician/clients/modals/Client'

export default {
	name: 'ManageClientRow',
	mixins: [dateTime, smallModalMixin],
	components: { CheckButton },
	props: ['itemKey'],
	computed: {
		client() { return this.$store.state.clients[this.itemKey] },
		/*
		clientTeams() { 
			if (Array.isArray(this.client.teamIds) && this.$store.state.manageClients.teamIds.length>1) {
				return this.client.teamIds.filter(teamId=>this.$store.state.manageClients.teamIds.includes(teamId)) 
			} else {
				return []
			}
		},
		*/
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		},		
		created() { 
			this.langBackdoor
			return this.date(this.client.created)
		},
		selected() { return this.$store.getters['manageClients/selected'](this.client.key) },
		newOutcome() {
			const notifications = Object.values(this.$store.state.notifications.notifications)
			return !!notifications.find(n=>n.clientId===this.client.id && n.type==='clientOutcomeCompleted' && n.viewed===false)
		}
	},
	methods: {
		onClick(e) {
			if (e.ctrlKey || e.metaKey || e.shiftKey || (this.selected && this.$store.state.manageClients.selected.length === 1)) return 
			this.$store.dispatch('manageClients/clearSelected')
			if (this.$store.state.manageClients.detail !== this.client.key) {
				this.$router.push({ name: 'clients', params: { clientId: this.client.key } }).catch(() => {})
			} else if (!this.selected) {
				this.$store.dispatch('manageClients/toggleSelected', this.client.key)
			}
		},
		onMouseDown(e) {			
			if (e.ctrlKey || e.metaKey) {
				this.toggleSelected(e)
			} else if (e.shiftKey) {
				this.selectUpTo()
			} else if (e.which && e.which===3) {
				if (!this.selected) {
					this.$store.dispatch('manageClients/clearSelected')			
					this.$store.dispatch('manageClients/toggleSelected', this.client.key)
				}
				this.$emit('showContextMenu', e)
			}
		},
		onDblClick(e) {
			if (e.ctrlKey || e.metaKey) return
			this.showSmallModal(ClientModal, { client: this.client })
		},
		toggleSelected(e) {
			if (e && e.shiftKey) this.selectUpTo()
			else if (this.$store.state.manageClients.selected.length) this.$store.dispatch('manageClients/toggleSelected', this.client.key)
			else if (this.$store.state.manageClients.detail !== this.client.key) this.$router.push({ name: 'clients', params: { clientId: this.client.key } }).catch(() => {})
			else this.onClick(e)
		},
		selectUpTo() {
			this.$store.dispatch('manageClients/selectUpTo', { key: this.client.key, list: this.$store.state.manageClients.list })
		}
	}
}
</script>

<style lang='scss'>
@import './scss/clientRow.scss';

.clientsManager .clientRow {

	.checkCol > div { pointer-events: none; }

	.lastnameCol {
		.emailErr { margin-right: $size-gutter * 2; color: $color-alert !important; }
	}

	.lastnameCol > span > span:first-child {
		margin-right: $size-gutter;
		> span.email { font-size: $size-font-small; color: $color-primary-accent; }
		> span.emailErr { font-size: $size-font-small; color: $color-alert !important; }
	}


	.lastname > span {}
	.lastnameCol .tags {
		transform: scale(0.9);
		transform-origin: 100% 50%;
		margin-left: auto;
		display: flex; gap: $size-gutter; 
	}

	.alert {
		color: $color-alert-accent;
		font-weight: bold;
	}

	.checkButton { height: 40px; padding: 0 $size-gutter * 2; margin-left: -$size-gutter * 2;}


	.feedback { 
		width: 24px; min-width: 24px; height: auto; margin-left: $size-gutter; margin-right: $size-gutter;
		border-radius: 50%;
/*
		&.alert { 
			box-shadow: 0 0 0 2px #ff5555;
			margin-top: 5px;
			margin-bottom: 5px;
		}
/*
		&.happy {
			box-shadow: 0 0 0 2px $color-success-accent;
			margin-top: 5px;
			margin-bottom: 5px;		
		}
*/
	}

	.newOutcome { color: $color-success; margin-left: $size-gutter; min-width: 16px; }

}
</style>