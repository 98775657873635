var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clientsHeader",on:{"click":function($event){$event.stopPropagation();}}},[_c('TableColumns',{attrs:{"module":_vm.module,"columns":[{
			label: _vm.$t("elements.cols.client"),
			selectable: true,
			sort: "lastname,firstname,email",
			flex: 3
		},{
			label: "Last Seen by",
			selectable: true,
			sort: _vm.$store.state.manageClients.shared ? "clinicianId" : undefined,
			flex: 1
		}]}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }