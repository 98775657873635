<template>
<div class='pointRow' :class='{ selected, emailed, saved }' @click.stop='onClick'>
	<svg class='smallIcon'>
		<use v-if='point.type === "save"' xlink:href='#save' />
		<use v-else xlink:href='#email' />
	</svg>
	<span class='event'>
		<template v-if='point.type === "save"'>{{$t('views.setHistory.savedOn')}}&nbsp;</template>
		<template v-else>{{$t('views.setHistory.emailedTo')}} <b><i>{{point.email}}</i></b> {{$t('elements.parts.on')}}&nbsp;</template>
	</span>
	<span class='on'>{{created}}&nbsp;</span>
	<span class='status' :class='[point.status]'>
		<template v-if="point.status==='delivery'">&nbsp;&nbsp;&nbsp;Email Delivered</template>
		<template v-if="point.status==='open'">&nbsp;&nbsp;&nbsp;Client Opened</template>
		<template v-if="point.status==='click'">&nbsp;&nbsp;&nbsp;Client Clicked</template>
		<template v-else-if="point.status==='bounce'">&nbsp;&nbsp;&nbsp;Email Bounced</template>
		<template v-else-if="point.status==='complaint'">&nbsp;&nbsp;&nbsp;Client Marked as Spam</template>
	</span>
	<span class='by'>{{by}}</span>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'

export default {
	name: 'PointRow',
	mixins: [dateTime],
	props: ['itemKey'],
	computed: {
		point() { return this.$store.state.setHistory.points[this.itemKey] },
		created() { return this.dateTime(this.point.created) },
		selected() { return this.point.key === this.$store.state.setHistory.selected },
		emailed() { return this.point.type === 'email' },
		saved() { return this.point.type === 'save' },
		by() { return `${this.$t('elements.parts.by')} ${this.point.savedBy || this.point.sentBy}` }
	},
	methods: {
		onClick() { this.$store.dispatch('setHistory/select', this.point.key) }
	}
}
</script>

<style lang='scss'>
.pointRow {
	overflow: hidden;
	position: relative;
	display: flex;
	align-items: center;
	font-size: $size-font-standard;
	height: $size-control-height;
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;
	margin-left: $size-gutter * 2;
	margin-right: $size-gutter * 2;
	margin-bottom: 1px;
	cursor: pointer;
	border-left: 1px solid $color-neutral-shadow;
	border-right: 1px solid $color-neutral-shadow;

	&.saved {
		background: $color-white;
	}

	&.emailed {
		background: $color-neutral-accent;
	}

	.on {
		font-weight: bold;
		color: $color-primary-accent;
	}

	.status {
				&.send { color: $color-primary-accent; }
				&.delivery, &.open, &.click  { color: $color-success; }
				&.bounce, &.complaint { color: $color-alert;  }
	}

	.by {
		margin-left: auto;
	}

	svg {
		margin-right: $size-gutter * 2;
		color: $color-neutral-shadow;
	}

	&.selected {
		background: $color-focus !important;
		> div { border-color: desaturate(darken($color-focus, 10%), 15%) !important; }
	}

}

.hover .pointRow.saved {
	background: $color-neutral-silver;
}
</style>