<template>
<div @click.stop>
	<div class='clientInfo' @click='editClient'><div>
		<header>
			{{client.firstname}} {{client.lastname}}
			<svg class='smallIcon'>
				<use xlink:href='#edit' />
			</svg>
			<!--<span v-if='client.clinicianName' class='clinicianName'>({{client.clinicianName}})</span>-->
			<!--
				<span v-if='client.emr' class='miniButton success'>{{$store.state.profile.emr.charAt(0).toUpperCase() + $store.state.profile.emr.slice(1)}}</span>
			-->
			<div 
				v-if='client.archived' 
				@click.stop='$store.dispatch("manageClients/archive", { archived: false, key: client.key })'
				class='miniButton success unarchive'
			>{{$t("elements.buttons.unarchive")}}</div>		

			<div v-else-if='client.emr' class='tag success emr'>{{$store.state.profile.emr.title.charAt(0).toUpperCase() + $store.state.profile.emr.title.slice(1)}}</div>

		</header>
		<div class='attrs'>
			<div v-if='client.email' class='email' :class='{ err: client.emailErr }'>{{client.email}}</div>
			<div v-if='client.email && client.portalKey'><b>{{$t('elements.labels.portalKey')}}:&nbsp;</b> {{client.portalKey}}</div>
			<div v-if='client.email'>{{lang}}</div>
		</div>
		<div class='tags'>
			<template v-if='client.teamIds'>
				<div 
					v-for='(teamId, index) in client.teamIds'
					:key='index'
					class='tag'
				>{{$store.state.profile.teams[teamId].title}}</div>
			</template>
			<div v-if='teams.length && !client.teamIds' class='tag alert'>Not Shared</div>
		</div>
		<!--
        <p>
            <b>{{$t('elements.labels.language')}}:</b> &nbsp;{{lang}}
            <template v-if='client.username'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>{{$t('elements.labels.username')}}:</b> &nbsp;{{client.username}} 
                <span class='miniButton neutral' @click.stop='resetPortal'>{{$t('elements.buttons.reset')}}</span>
            </template>
        </p>
		<p v-if='client.email' class='email'>
			<span><b>{{$t('elements.labels.email')}}:&nbsp;</b> {{client.email}}</span>
            <template v-if='client.portalKey'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span><b>{{$t('elements.labels.portalKey')}}:&nbsp;</b> {{client.portalKey}}</span>
            </template>			
		</p>
		<p v-else>&nbsp;</p>   
		-->     		
	</div>
	<transition name='fade'><Loading v-show='loading' /></transition>
</div></div>
</template>

<script>
import { clientsApi } from '@/services/api/modules/clinician/clients'
import { smallModalMixin } from '@/components/common/mixins/modal'
import { dateTime } from '@/components/common/mixins/dateTime'
import Loading from '@/components/common/Loading'
import ConfirmModal from '@/components/common/modals/Confirm'
import ClientModal from './modals/Client'

export default {
	name: 'ClientInfo',
	mixins: [dateTime, smallModalMixin],
	components: { Loading },
	props: ['client'],
	data: () => ({ loading: false }),
	computed: {
		lang() { return this.$t('elements.langs.'+ this.client.lang )},
		teams() {
			return this.$store.state.profile.teams ? Object.values(this.$store.state.profile.teams).filter(t=>t.teamClients) : []
		},
	},
	methods: {
		editClient() { 
			this.showSmallModal(ClientModal, { client: this.client }) 
		},
		resetPortal() {
			this.showSmallModal(ConfirmModal, {
				title: this.$t('views.resetPortal.title'),
				message: this.$t('views.resetPortal.p1'),
				ok: this.$t('elements.buttons.reset'),
				fn: async () => {
					this.loading = true
					const [err] = await clientsApi.touch('resetPortal', { id: this.client.key })
					this.loading = false
					if (!err) this.$store.dispatch('clients/resetPortal', this.client.key)
				}
			})
		}
	}
}
</script>

<style lang='scss'>
.clientInfo {
	position: relative;
	display: flex;
	align-items: flex-start;
	padding-left: $size-gutter * 2;
	cursor: pointer;
	padding-bottom: $size-gutter;
	min-height: 120px;
	> div { flex: 1; }

	header {
		display: flex;
		align-items: center;
		svg { margin-left: $size-gutter * 2; }
		height: $size-control-height;
		font-size: $size-font-large;
		font-weight: bold;
		border-bottom: 1px solid #e8e8e8;
		margin-top: 0;

		.miniButton {
			margin-right: $size-gutter * 2;
		}

		span { margin-left: $size-gutter * 2; }
	}

	.unarchive { margin-left: $size-gutter * 2; }

	.attrs {
		padding-top: $size-gutter * 2;
		display: flex;
		column-gap: $size-gutter * 2;
		> div {
			height: 24px;
			font-size: $size-font-standard;
			white-space: nowrap;
			display: flex;
			align-items: center;
			&.email { 
				color: $color-primary-accent; 
				&.err { color: $color-alert; }
			}
		}
	}

	.emr { margin-left: auto; margin-right: $size-gutter * 2; }

	.tags {
		display: flex;
		column-gap: 1px;
	}

	p {
		display: flex;
		.miniButton {
			margin-left: $size-gutter * 2;
			position: relative;
			top: -3px;
			margin-bottom: -$size-gutter;
		}
		&.email { margin-top: 4px;}
	}
}
</style>
