<template>
<SmallModal class='confirmDeleteModal' @close='$emit("close")' @doDefault='doFn'>
	<template v-slot:title>{{$t("views.confirmDelete.title")}}</template>
	<template v-slot:message>
		<p>{{p1 || $t("views.confirmDelete.p1")}}</p>
		<p class='items'>
			<template v-if='Array.isArray(items)'>
				<b v-for='(item, index) in (items.length > 20 ? items.slice(0, 20) : items)' :key='index'>{{item}}</b>
				<b v-if='items.length > 20'>({{items.length - 20}} {{$t('elements.parts.more')}})</b>
			</template>
			<template v-else>
				<b>{{items}}</b>
			</template>
		</p>
		<p v-html='p2 || $t("views.confirmDelete.p2")' />
	</template>
	<template v-slot:buttons>
		<DeleteButton @click='doFn' />
	</template>
</SmallModal>
</template>

<script>
/*
when the time comes to redo this
just have a single dialog component that can have a custom color
and inject the message and the buttons via slots and a callback in the form of a promise
*/
import DeleteButton from '@/components/common/buttons/Delete'
import SmallModal from './SmallModal'

export default {
	components: {
		SmallModal,
		DeleteButton
	},
	props: ['items', 'fn', 'p1', 'p2'],
	data: () => ({ fnDone: false }),
	methods: {
		doFn() {
			if (this.fnDone) return
			this.fnDone = true
			this.$emit('close')
			if (typeof this.fn === 'function') this.fn()
		}
	}
}
</script>

<style lang='scss'>
.confirmDeleteModal {
	header { 
		color: $color-alert !important;
		border-bottom: 1px solid $color-alert !important;
	}

	p span { color: $color-alert; font-weight: bold; }
	b { color: $color-primary-accent;}

	.items {
		padding-left: $size-gutter * 2;
		padding-top: $size-gutter;
		margin-bottom: $size-gutter;
		color: $color-primary-accent;
		b { display: block }
	}
}
</style>