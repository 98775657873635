<template>
<SmallModal class='confirmModal' @close='$emit("close")' @doDefault='doFn' :doDefaultOnEnter='doDefaultOnEnter'>
	<template v-slot:title>{{title}}</template>
	<template v-slot:message>
		<template v-if='message'>
			<template v-if='Array.isArray(message)'>
				<p v-for='(section, index) in message' :key='index' v-html='section' />
			</template>
			<p v-else v-html='message' />
		</template>
		<div v-else-if='html' v-html='html' />
	</template>
	<template v-slot:buttons>
		<div class='iconTextButton primary' @click='doFn'>
			<svg class='smallIcon'>
				<use xlink:href='#check' />
			</svg>
			<span class='label'>{{ok}}</span>
		</div>
	</template>
</SmallModal>
</template>

<script>
import SmallModal from './SmallModal'

export default {
	components: {
		SmallModal
	},
	props: ['title', 'message', 'html', 'ok', 'fn', 'doDefaultOnEnter'],
	data: () => ({ fnDone: false }),
	methods: {
		doFn() {
			if (this.fnDone) return
			this.fnDone = true
			this.$emit('close')
			if (typeof this.fn === 'function') this.fn()
		}
	}
}
</script>

<style lang='scss'>
.confirmModal {
	b { color: $color-primary-accent; }
}
</style>