<template>
<div class='manageClientSetsHeader' @click.stop>
	<TableColumns 
		:module='module'
		:columns='[{
			label: $t("elements.cols.exerciseSet"),
			selectable: true,
			sort: "savename",
			flex: 4.5
		},{
			label: "Last Saved",
			selectable: true,
			sort: "updated",
			flex: 2
		},{
			label: "Last Sent",
			selectable: false,
			flex: 2
		},{
			label: $t("elements.cols.onOff"),
			selectable: true,
			sort: "enabled",
			flex: 2
		}]'
	/>
</div>
</template>

<script>
import TableColumns from '@/components/common/TableColumns'

export default {
	name: 'ClientSetsHeader',
	props: ['module'],
	components: { TableColumns }
}
</script>

<style lang='scss'>
.manageClientSetsHeader {
	position: sticky;
	top: 40px;
	z-index: 100;
	padding-left: $size-gutter * 2;
	padding-right: $size-gutter * 2;
}
</style>