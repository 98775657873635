<template>
<div v-if='Array.isArray(data)' class='clientFeedback'>
	<div class='header'>
		Client Feedback
		<div v-if="feeling">
			{{client.firstname}} {{$t('views.clientFeedback.isFeeling')}}
			<img :src="feeling" />
		</div>
	</div>
	<div class='content'>
		<div class='faces'><div>
			<img src="/img/faces/0.png" />
			<img src="/img/faces/1.png" />
			<img src="/img/faces/2.png" />
			<img src="/img/faces/3.png" />
			<img src="/img/faces/4.png" />
		</div></div>
		<div class='chart'><Chart v-if='data.length' :scores='data' :key='renderKey' /></div>
		<div class='events'><div><div>
			<div v-for='(event, i) in data' class='event' :key='i'>
				<img :src="`/img/faces/${event.score}.png`" />
				<div>
					<span class='date'>{{date(event.created)}}</span>&nbsp;
					<span v-if='event.comments' class='comments'>{{event.comments}}</span>
				</div>
			</div>
		</div></div></div>
		<!--
			<div class='currFace'>
				<div>
					<img :src="`/img/faces/${client.feedbackScore}.png`" />
					<div v-if="data[0] && data[0].comments" class="comments" disabled>&quot;{{data[0].comments}}&quot;</div>
				</div>
			</div>

		-->
		<div class='dates'>
			<span>{{startDate}}</span>
			<span v-if='endDate !== startDate'>{{endDate}}</span>		
		</div>
	</div>
</div>
</template>

<script>
import dayjs from "dayjs"
import { smallModalMixin } from '@/components/common/mixins/modal'
import { clientsApi } from '@/services/api/modules/clinician/clients'
import { dateTime } from '@/components/common/mixins/dateTime'
import Chart from './ClientFeedbackChart'

/*
import ClientPanelTabs from './ClientPanelTabs'
import ClientSets from './ClientSets'
import ClientFeedback from './ClientFeedback'
*/

export default {
	name: 'clientFeedback',
	mixins: [dateTime, smallModalMixin],
	components: { Chart },
	props: ['client'],
	data() { return {
		loading: false,
		data: false,
		renderKey: 1
	}},
	computed: {
		startDate() {
			this.langBackdoor
			return this.data.length ? this.date(this.data[this.data.length-1].created) : ''
		},
		endDate() {
			this.langBackdoor
			return this.data.length ? this.date(this.data[0].created) : ''			
		},
		feeling() {
			if (this.data.length) {
				const event = this.data[0]
				const d1 = dayjs()
				const d2 = dayjs(event.created)
				if (d1.diff(d2, 'day') < 14) {
					return `/img/faces/${event.score}.png`
				} 
			}
			return false
		}
	},
	methods: {
		async fetch() {
			this.loading = true
		//	this.data = []
			const [err, result] = await clientsApi.touch('fetchClientFeedback', { id: this.client.id })
			if (!err) this.data = result
			this.loading = false			
			this.$store.commit('notifications/markRead', { clientId: this.client.id, type: 'clientFeedbackProvided' })
			this.renderKey++
		}
	},
	watch: {
		client(a, b) {
			if (a!==b) this.fetch()
		}
	},
	mounted() {
		this.fetch()
	}
}
</script>

<style lang='scss'>
.clientFeedback {

	margin-top: $size-gutter * 4;

	.header {
		height: $size-control-height;
		display: flex;
		align-items: center;
		font-size: $size-font-standard;
		padding-left: $size-gutter * 2;
		margin: $size-gutter * 2;
		margin-bottom: 0;
		img { width: 26px; height: 26px; margin-left: $size-gutter * 2; }
		> div {
			margin-left: auto;
			margin-right: $size-gutter * 2;
			display: flex;
			align-items: center;
		}
	}

	.content {
		margin: $size-gutter * 2;
		margin-top: 0;
		border: 1px solid $color-neutral-shadow;
		display: grid;
		grid-template-columns: 40px 1.5fr 1fr;
		background: $color-white;
		position: relative;
		> div { position: relative; }
	}

	.faces {
		margin-top: $size-gutter * 4;
		margin-left: $size-gutter * 2;
		> div { position: absolute; top: 5px; left: 0; width: 100%; height: 155px; }
		img {
			position: absolute;
			left: 0;
			width: 26px;
			height: 26px;
			margin-top: -13px;
			&:nth-child(1) { top: 100%; }
			&:nth-child(2) { top: 75%; }
			&:nth-child(3) { top: 50%; }
			&:nth-child(4) { top: 25%; }
			&:nth-child(5) { top: 0; }
		}
	}
	
	.chart { 
		position: relative;
		height: 170px;
		margin-top: $size-gutter * 4;
		margin-left: $size-gutter * 2;
		> div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
	}

	.events {
		grid-column: 3;
		grid-row: 1/3;
		position: relative;
		background: $color-neutral-silver;
		> div {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			display: flex;
			overflow: scroll;
			> div { width: 100%; margin: auto; }
		}
		.event {
			display: flex;
			align-items: center;
			padding: $size-gutter * 2 0;
			&:first-child { background: $color-focus; }
			&:not(:last-child) {
				border-bottom: 1px solid #fff;
			}
			> img {
				width: 26px;
				height: 26px;
				margin: 0 $size-gutter * 2;
			}
			> div { 
				flex: 1; 
				padding-right: $size-gutter * 2; 
				font-size: $size-font-small;
				line-height: $size-lineHeight-small;
				.date { font-weight: bold; color: $color-primary-accent; }
			}
		}
	}


	.currFace {
		grid-row: 1/3;
		grid-column: 3; 		
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $size-font-standard;
		line-height: $size-lineHeight-standard; 
		font-weight: bold;
		color: $color-primary;
		text-align: center;
		img { width: 55px; height: 55px; display: inline-block; }		
		span { text-decoration: underline; cursor: pointer; }
		> div { flex: 1; }
	}

/*
	.comments {
		height: 142px;
		text-align: left;
		margin-top: $size-gutter;
		padding: $size-gutter * 2;
		background: $color-focus;
		overflow: scroll;
		font-weight: normal;
		color: $color-black;
	}
*/

	.dates { 
		grid-column: 2; 
		display: flex;
		justify-content: space-between;
		font-size: $size-font-standard;		
		padding-left: $size-gutter * 3;
		padding-right: $size-gutter * 3;
		margin-bottom: $size-gutter;
		height: $size-control-height;
		align-items: center;
	}

/*
	> div {
		position: relative;
		height: 170px;
		width: 100%;
		> div { position: absolute; top: 0; left: 0; width: calc(100% - 134px); height: 100%; }	
	}

	.faces { 
		position: absolute;
		top: 0;
		left: 0;
		width: 40px;
		height: calc(100% - 16px);
		margin-top: -8px;
		> img {
			position: absolute;
			left: 0;
			width: 26px;
			height: 26px;
			&:nth-child(1) { top: 100%; }
			&:nth-child(2) { top: 75%; }
			&:nth-child(3) { top: 50%; }
			&:nth-child(4) { top: 25%; }
			&:nth-child(5) { top: 0; }
		}
	}

	.dates {
		position: absolute;
		left: 0;
		top: 170px;
		width: calc(100% - 134px);
		display: flex;
		justify-content: space-between;
		font-size: $size-font-standard;
		padding-left: 49px;
		padding-right: 5px;
	}

	.currentFace {
		position: absolute;
		top: 0;
		right: 0;
		height: 100%;
		width: 147px;
		display: flex;
		align-items: center;
		justify-content: center;
		img { width: 55px; height: 55px; }
	}

*/
}
</style>
