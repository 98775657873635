<template>
<div class='tracking'>
	<div class='contentPanel'>
		<template v-if='loaded'>
			<div class='grid'>
				<div class='painPanel'>
					<div>
						<header>Pain (0-10) While Exercising</header>
						<div v-if='pain.length' class='chartDates'>{{date(pain[pain.length - 1].created)}} - {{date(pain[0].created)}}</div>
					</div>					
					<div>
						<Chart :scores='pain' />
					</div>
				</div>
				<div class='rpePanel'>
					<div>
						<header>RPE (0-10) While Exercising</header>
						<div v-if='rpe.length' class='chartDates'>{{date(rpe[rpe.length - 1].created)}} - {{date(rpe[0].created)}}</div>
					</div>
					<div>
						<Chart :scores='rpe' />
					</div>
				</div>
				<div class='reportsPanel'>
					<Scroll :fetchNext='fetchNext' :loading='loadingReports'>
						<header>
							<span>Exercise Reports:</span> {{client.firstname}} {{client.lastname}}
						</header>
						<div class='reportsWrapper'>
							<div v-for='(item, index) in reports.items' class='report' :key='index'>
								<div class='header'>
									<div>
										<template v-if='client.firstname'>{{client.firstname}} exercised on </template>
										<template v-else>Client exercised on </template>
										<b>{{date(item.created)}}</b> <template v-if='item.dur'> for <b>{{formatDur(item.dur)}}</b></template>
									</div>
									<div><span class='miniButton neutral' @click='fetchHistory(item.historyId)'>View Exercises</span></div>
								</div>
								<div class='scores'>
									<div>
										<span v-if='item.completed' class='miniButton success'>Finished</span>
										<span v-else class='miniButton alert'>Did not Finish</span>
									</div>
									<div>Pain: <template v-if='item.pain===null'>N/A</template><span v-else class='miniButton' :class='getLabelColorPain(item.pain)'>{{item.pain}}</span></div>
									<div>RPE: <template v-if='item.rpe===null'>N/A</template><span v-else class='miniButton' :class='getLabelColorRPE(item.rpe)'>{{item.rpe}}</span></div>
								</div>
								<div v-if='item.comment' class='comment'><span>Comments: </span> {{item.comment}}</div>
							</div>
						</div>
					</Scroll>
				</div>
				<div class='setPanel'>
					<Scroll ref='previewScroll'>
						<SetPreview v-if='exerciseSet' :exerciseSet='exerciseSet' :resources='resources' :latest='latestExercises' />
					</Scroll>
					<transition name='fade'><Loading v-show='loadingPreview' /></transition>
				</div>
			</div>
		</template>
		<transition name='fade'><Loading v-show='loading || loadingReports' /></transition>
	</div>
	<div class='modalControls'>
		<div class='iconTextButton neutral' @click='$emit("close")'>
			<svg class='smallIcon'>
				<use xlink:href='#cancel' />
			</svg>
			<span class='label'>{{$t('elements.buttons.close')}}</span>
		</div>
		<div v-if='showLoad' class='iconTextButton success' @click='loadSet'>
			<svg class='smallIcon'>
				<use xlink:href='#designer' />
			</svg>
			<span class='label'>Edit Exercises</span>
		</div>
	</div>
</div>
</template>

<script>
import { dateTime } from '@/components/common/mixins/dateTime'
import { setsApi } from '@/services/api/modules/clinician/sets'
import { smallModalMixin } from '@/components/common/mixins/modal'
import Alert from '@/components/common/modals/Alert'
import Loading from '@/components/common/Loading'
import Scroll from '@/components/common/Scroll'
import SetPreview from '@/components/clinician/preview/Preview'
import Chart from './Chart'

export default {
	name: 'Tracking',
	mixins: [ dateTime, smallModalMixin ],
	components: { Loading, Scroll, Chart, SetPreview },
	props: ['setId', 'client', 'showLoad'],
	data() { return { 
		loading: false,
		loaded: false,
		loadingReports: false,
		loadingPreview: false,
		pain: null,
		skip: 0,
		reports: {
			total: null,
			items: []
		},
		exerciseSet: null,
		resources: null
	}},
	computed: {
		latestExercises() { return this.exerciseSet && this.exerciseSet.id === this.reports.items[0].historyId }
		/*
		client() {
			return this.$store.state.clients[this.clientId]
		}
		*/
	},
	methods: {
		async fetchInitial() {
			this.loading = true
			const [err, result] = await setsApi.touch('fetchReports', { id: this.setId, bootstrap: true, skip: this.skip })
			this.loading = false
			this.loaded = true
			if (!err) {
				this.pain = result.pain
				this.rpe = result.rpe
				this.reports.total = result.total 
				this.reports.items = result.items
				if (this.reports.items.length) this.fetchHistory(this.reports.items[0].historyId)
				else this.noReports()
			} else {
				this.$emit('close')
			}
		},
		async fetchNext() {
			if (this.reports.items.length < this.reports.total) {
				this.skip = this.reports.items.length
				this.loadingReports = true
				const [err, result] = await setsApi.touch('fetchReports', { id: this.setId, skip: this.skip })
				this.loadingReports = false
				if (!err) this.reports.items.push(...result.reports.items)
			}
		},
		async fetchHistory(historyId) {
			this.loadingPreview = true
			const [err, result] = await setsApi.touch('fetchHistoryPoint', { id: historyId })
			this.loadingPreview = false
			if (!err) {
				const { point, resources } = result
				this.exerciseSet = point
				this.resources = resources
				this.$refs.previewScroll.scrollToTop()
			}
		},
		formatDur(dur) {
			const hours = Math.floor(dur / 60)
			const minutes = dur - (hours * 60)
			let time = ''
			if (hours) {
				time += hours + ' '
				if (hours === 1) time += this.$t('elements.parts.hour')
				else time += this.$t('elements.parts.hours')
				if (minutes) time += ' ' + this.$t('elements.parts.and') + ' '
			}
			if (minutes) {
				time += minutes + ' ' + this.$t('elements.parts.minutes')
			}
			return time
		},
		getLabelColorPain(v) {
			if (v <= 2) return `success`
			else if (v <= 5) return `accent`
			else return `alert`
		},
		getLabelColorRPE(v) {
			if (v <= 5) return `success`
			else if (v <= 7) return `accent`
			else return `alert`
		},
		loadSet() {
			this.loading = true
			this.$store.dispatch('setBuilder/fetch', this.setId)
				.then(() => {
					this.$emit('close')
					this.$router.replace({ name: 'designer' })
				})			
		},
		noReports() {
			this.$emit('close')
			this.showSmallModal(Alert, { title: 'No Adherence Tracking Reports', message: `${this.client.firstname} ${this.client.lastname} has not completed any reports for this exercise set.`  })
		}
	},
	mounted() {
		this.fetchInitial()
	}
}
</script>

<style lang='scss'>
.tracking {
	@include fill;
	display: grid;
	grid-template-rows: 1fr auto;
	background: $color-white;

	.contentPanel {
		position: relative;
		padding: $size-gutter * 4;

		.grid {
			display: grid;
			grid-template-columns: 1fr 1.5fr 1fr;
			grid-template-rows: 1fr 1fr;
			grid-gap: 1px;
			background: $color-neutral-shadow;
			border: 1px solid $color-neutral-shadow;
			
			height: 100%;

			> div {
				position: relative;
				background: $color-neutral-silver;
				
			}

			.reportsPanel, .setPanel { grid-row: 1/3; }
			.reportsPanel { grid-column-start: 2; }
			.setPanel { grid-column-start: 3; }
			.painPanel, .rpePanel {
				display: grid;
				grid-template-rows: auto 1fr;
				> div { position: relative; }
				> div:first-child { 
					padding: $size-gutter * 2; 
					padding-top: $size-gutter * 4; 
					padding-bottom: 0;
				}
				> div:last-child > div { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
				.chartDates { margin-top: $size-gutter; font-size: $size-font-standard; text-align: center; }
			}
		}

		.reportsPanel header {
			margin-top: $size-gutter * 4;
		}

		.reportsWrapper {
			margin: $size-gutter * 4;
		}

		.report {
			padding: $size-gutter * 2;
			background: $color-white;
			margin-bottom: $size-gutter * 2;	
			border: 1px solid $color-neutral-shadow;

			.header {
				display: flex;
				align-items: center;
				font-size: $size-font-large;
				padding-bottom: $size-gutter * 2;
				b { color: $color-primary-accent; }
				> div:last-child { margin-left: auto; }
				.miniButton { border: 1px solid $color-neutral-shadow; }
			}

			.scores {
				display: flex;
				font-size: $size-font-standard;
				> div { flex: 1; display: flex; align-items: center; justify-content: center; margin-right: $size-gutter * 2; }
				> div:not(:first-child) {
					border-left: 1px solid $color-neutral-shadow;
					.miniButton { margin-left: $size-gutter; }
				}
			}

			.comment {
				padding-top: $size-gutter * 2;
				font-size: $size-font-large;
				line-height: $size-lineHeight-large;				
				font-style: italic;
				color: #999;
				span { color: $color-black; font-style: normal; }
			}

		}

		header {
			padding: 0;
			font-size: $size-font-large; 
			color: $color-primary-accent; 
			font-weight: bold;
			text-align: center;
			span { color: $color-black; }
		}

	}

	.loading {
		background: rgba($color-white, 0.3);
	}

	.modalControls {
		grid-column: -1 / 1;
	}
}
</style>
