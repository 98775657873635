<template>
<div 
	class='draggableSwitch trackFalse' 
	:class='{ animateToggle }' 
	:style='`width: ${this.width}px;`'
	@mousedown.stop
	@click.stop='onClick'
>
	<div 
		class='trackTrue' 
		:style='`opacity: ${1 - this.position / this.activeWidth};`'
	/>
	<div
		class='handle'
		:style='style'
	/>
	<!--
	<div
		class='handle'
		:style='style'
		@mousedown='onDragStart'
	/>
-->
</div>
</template>

<script>
export default {
	name: 'DraggableSwitch',
	props: ['value', 'clickable'],
	data() {
		return {
			v: this.value,
			width: 30,
			handleWidth: 16,
			offset: 0,
			position: 0,
			animateToggle: false
		}
	},
	computed: {
		style() { 
			return { transform: `translateX(${this.position}px)` }
		},
		activeWidth() {
			return this.width - this.handleWidth
		}
	},
	methods: {
		onDragStart(e) {
			this.offset = e.offsetX
			window.addEventListener('mousemove', this.onDrag)
			window.addEventListener('mouseup', this.onDragStop)
		},
		onDragStop() {
			window.removeEventListener('mousemove', this.onDrag)
			window.removeEventListener('mouseup', this.onDragStop)
			if (this.clickable) return this.onClick()
			this.animate()
			if (this.position >= this.activeWidth / 2) {
				this.position = this.activeWidth
				if (this.v) {
					this.v = false
					this.$emit('input', this.v)
				}
			} else {
				this.position = 0
				if (!this.v) {
					this.v = true
					this.$emit('input', this.v)
				}
			}
		},
		onDrag(e) {
			const pos = e.clientX - this.$el.getBoundingClientRect().left - this.offset
			this.position = pos <= 0 ? 0 : pos >= this.activeWidth ? this.activeWidth : pos
		},
		onClick() {
			//if (!this.clickable) return
			this.animate()
			if (this.v) {
				this.position = this.activeWidth
				this.v = false
			} else {
				this.position = 0
				this.v = true
			}
			this.$emit('input', this.v)
		},
		set() {
			this.position = this.value ? 0 : this.activeWidth 
			this.v = this.value
		},
		animate() {
			this.animateToggle = true
			setTimeout(() => this.animateToggle = false, 300)
		}
	},
	watch: {
		value(v) { if (v !== this.v ) this.set() }
	},
	mounted() {
		this.set()
	}
}
</script>
<style lang='scss'>
.draggableSwitch {
	position: relative;
	height: 16px;
	border-radius: 8px;
	min-width: 30px;
	cursor: pointer;
	transform: scaleX(-1);

	.handle {
		position: relative;
		z-index: 2;
		top: 1px;
		left: 1px;
		width: 14px;
		height: 14px;
		border-radius: 7px;
		background: $color-white;
		/*cursor: grab; */
	}

	.trackTrue {
		@include absTopLeft;
		@include fill;
		z-index: 1;
		border-radius: 10px;
		background: $color-success; 		
	}

	background: $color-neutral-dark;

	&.animateToggle {
		.handle {
			transition: transform 200ms linear;
		}
		.trackTrue {
			transition: opacity 200ms linear;
		}
	}

}
</style>