<template>
<EnabledButton @click='$emit("click")' :disabled='disabled' :svg='"#delete"' :label='$t("elements.buttons.delete")' class='neutral' />
</template>

<script>
import EnabledButton from './Enabled'

export default {
	name: 'DeleteButton',
	props: ['disabled'],
	components: {
		EnabledButton
	}
}
</script>